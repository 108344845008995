import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewsModel } from 'projects/shared-models/src/lib/news.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    newsUrl = environment.apiUrl + '/news';

    constructor(private http: HttpClient) {}

    getNews(
        filters: Object,
        sort = 'createDate,desc',
        pageNumber = 0,
        pageSize = 5,
        exclusiveContent = true
    ): Observable<Array<NewsModel>> {
        let params = new HttpParams()
            .set('sort', sort)
            .set('page', pageNumber.toString())
            .set('size', pageSize.toString());

        if (exclusiveContent) {
            params = params.set('exclusiveContent', 'true');
        } else {
            params = params.set('publicContent', 'true');
        }

        //add filters to headers
        for (let filterName of Object.keys(filters)) {
            if (filters[filterName].length > 0) {
                params = params.set(filterName, filters[filterName]);
            }
        }

        return this.http
            .get<any>(this.newsUrl, {
                params,
            })
            .pipe(map((res) => res.content));
    }

    getNewsFeed(topics: Array<string>) {
        let params = new HttpParams().set('topic', topics.join(','));

        return this.http.get<any>(environment.apiUrl + '/public/news', {
            params,
        });
    }

    getTags(): Observable<Array<string>> {
        return this.http
            .get<any>(this.newsUrl + '/tags')
            .pipe(map((res) => res.tags));
    }

    getNewsByID(id: string): Observable<NewsModel> {
        return this.http.get<any>(this.newsUrl + '/' + id);
    }

    getMainStory(exclusiveContent): Observable<NewsModel> {
        return this.http.get<NewsModel>(
            this.newsUrl + '/main-story?publicContent=' + !exclusiveContent
        );
    }

    getStory(title: string): Observable<NewsModel> {
        return this.http.get<NewsModel>(this.newsUrl + '/' + title);
    }
}
