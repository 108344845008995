<app-page-layout>
    <div pageContent class="media-container">
        <div class="sign-in-overlay" *ngIf="showSignIn">
            <div class="sign-in-card">
                <span
                    class="material-icons close-icon"
                    (click)="showSignIn = false"
                >
                    close
                </span>
                <div class="title">Read the full story</div>
                <div class="purple-delimiter"></div>
                <div class="text">
                    Continue reading with a My House of Lab membership or
                    subsrcribe to our news
                </div>
                <a class="sign-in-button" href="/dashboard">Sign In</a>
            </div>
        </div>
        <div class="top-background">
            <div class="title">
            </div>
        </div>
        <div class="page-content">
            <div class="filters" [class.hide]="hideFilters">
                <div class="filters-top">
                    <span (click)="expandFilters = !expandFilters"
                        >Filters <i class="material-icons">filter_list</i></span
                    >
                    <input
                        placeholder="Search ..."
                        class="search-filter"
                        #searchFilterInput
                        [(ngModel)]="searchFilter"
                    />
                </div>

                <div class="filters-container" [class.hide]="!expandFilters">
                    <div class="filters-wrapper">
                        <div>
                            <ng-multiselect-dropdown
                                [placeholder]="'Category'"
                                [settings]="dropdownSettings"
                                [data]="newsTypeList"
                                [(ngModel)]="categoryFilter"
                                (onDropDownClose)="filtersChanged()"
                            >
                            </ng-multiselect-dropdown>
                        </div>

                        <div>
                            <ng-multiselect-dropdown
                                [placeholder]="'Year'"
                                [settings]="dropdownSettings"
                                [data]="yearsList"
                                [(ngModel)]="yearFilter"
                                (onDropDownClose)="filtersChanged()"
                            >
                            </ng-multiselect-dropdown>
                        </div>

                        <div>
                            <ng-multiselect-dropdown
                                [placeholder]="'Tag'"
                                [settings]="dropdownTagsSettings"
                                [data]="tagList"
                                [(ngModel)]="tagFilter"
                                (onDropDownClose)="filtersChanged()"
                            >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>

                    <button class="reset-filter" (click)="resetFilters()">
                        Reset filters
                    </button>
                </div>
            </div>
            <div class="news-section">
                <div class="press-release" id="article">
                    <div class="news-author-date">
                        <a
                            *ngIf="selectedNews?.link"
                            [href]="selectedNews?.link"
                            class="author"
                            >{{ selectedNews?.author }}</a
                        >
                        <span *ngIf="!selectedNews?.link" class="author">{{
                            selectedNews?.author
                        }}</span>
                        &#183;
                        <div class="date">
                            {{ selectedNews?.createDate | date: "dd MMM yyyy" }}
                        </div>
                    </div>
                    <div class="pre-title">
                        <div class="news-tags">
                            <div
                                class="news-tag"
                                *ngFor="let tag of selectedNews?.tags"
                            >
                                {{ tag }}
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="article-type"
                                [ngClass]="
                                    selectedNews?.category
                                        ? selectedNews?.category
                                              .split(' ')[0]
                                              .toLowerCase()
                                        : ''
                                "
                            >
                                {{ selectedNews?.category }}
                            </div>
                            <span
                                title="Share"
                                class="material-icons-outlined share-button"
                                (click)="copyHyperlink(selectedNews)"
                            >
                                share
                            </span>
                        </div>
                    </div>
                    <div class="title">
                        {{ selectedNews?.title }}
                    </div>
                    <div>
                        <img [src]="selectedNews?.picture" />
                    </div>
                    <div
                        class="text"
                        [class.h-auto]="showMoreOn"
                        [innerHTML]="
                            decodeText(selectedNews?.content) | safeHtml
                        "
                    ></div>
                    <div
                        class="press-buttons"
                        [class.has-attachment]="selectedNews?.attachment"
                    >
                        <a
                            class="download-button"
                            *ngIf="selectedNews?.attachment"
                            [href]="selectedNews?.attachment"
                            target="_blank"
                        >
                            <div class="icon-download"></div>
                            <div>Download</div>
                        </a>
                        <div
                            class="show-more"
                            *ngIf="!showMoreOn"
                            (click)="showMoreOn = true"
                        >
                            Read more
                        </div>
                        <div
                            class="show-less"
                            *ngIf="showMoreOn"
                            (click)="showMoreOn = false"
                        >
                            Read less
                        </div>
                    </div>
                </div>
                <div class="articles">
                    <div class="title-delimiter-container">
                        <div class="title">News</div>
                        <div class="delimiter"></div>
                    </div>
                    <div class="articles-container">
                        <cdk-virtual-scroll-viewport
                            itemSize="100"
                            #scroller
                            class="cards-infinite"
                        >
                            <div *cdkVirtualFor="let news of newsList">
                                <div class="article-wrap">
                                    <div class="article-main-info">
                                        <img
                                            [src]="news?.picture"
                                            (click)="selectNews(news)"
                                        />
                                        <div class="article-info">
                                            <div>
                                                <div class="article-title">
                                                    <div
                                                        class="article-type"
                                                        [ngClass]="
                                                            news?.category
                                                                ? news?.category
                                                                      .split(
                                                                          ' '
                                                                      )[0]
                                                                      .toLowerCase()
                                                                : ''
                                                        "
                                                    >
                                                        {{ news?.category }}
                                                    </div>
                                                </div>
                                                <a (click)="selectNews(news)">{{
                                                    news?.title
                                                }}</a>
                                            </div>
                                            <div class="news-author-date">
                                                <a
                                                    *ngIf="news?.link"
                                                    [href]="news?.link"
                                                    class="author"
                                                    >{{ news?.author }}</a
                                                >
                                                <span
                                                    *ngIf="!news?.link"
                                                    class="author"
                                                    >{{ news?.author }}</span
                                                >
                                                &#183;
                                                <div class="date">
                                                    {{
                                                        news?.createDate
                                                            | date
                                                                : "dd MMM yyyy"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="news-tags">
                                        <div
                                            class="news-tag"
                                            *ngFor="let tag of news?.tags"
                                        >
                                            {{ tag }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </div>
                </div>
            </div>
            <div class="exclusive-section">
                <div class="exclusive-header">Exclusive content</div>
                <div class="delimiter"></div>
                <div class="exclusive-content">
                    <a
                        class="exclusive-card"
                        *ngFor="let news of newsListExclusive"
                        [href]="'/media-article/' + getTitle(news.title)"
                        target="_blank"
                    >
                        <div
                            class="article-type"
                            *ngIf="news.category"
                            [ngClass]="
                                news?.category
                                    ? news?.category.split(' ')[0].toLowerCase()
                                    : ''
                            "
                        >
                            <!-- <span class="material-icons my-lock"> lock </span> -->
                            {{ news?.category }}
                        </div>
                        <img *ngIf="news.picture" [src]="news?.picture" />
                        <div class="title">
                            {{ news?.title }}
                        </div>
                        <div
                            class="text"
                            [innerHTML]="decodeText(news?.content) | safeHtml"
                        ></div>
                        <div *ngIf="news" class="news-author-date">
                            <span *ngIf="!news?.link" class="author">{{
                                news?.author
                            }}</span>
                            &#183;
                            <div class="date">
                                {{ news?.createDate | date: "dd MMM yyyy" }}
                            </div>
                        </div>
                        <div class="news-tags">
                            <div
                                class="news-tag"
                                *ngFor="let tag of news?.tags"
                            >
                                {{ tag }}
                            </div>
                        </div>
                    </a>
                </div>
                <div *ngIf="showMore" class="more-button">
                    <span (click)="loadExclusiveNews()">More news</span>
                </div>
            </div>
        </div>
    </div>
</app-page-layout>
