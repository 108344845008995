export const NewsCategoryExclusiveList = [
    'HLS weekly digest',
    'Industry insight',
];

export const NewsCategoryPublicList = [
    'Article',
    'Announcement',
    'Interview',
    'Case study',
];

export const NewsCategory = NewsCategoryPublicList.concat(NewsCategoryExclusiveList);
