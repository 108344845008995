import { Component, OnInit } from '@angular/core';
import { HlsServicesService } from 'src/app/services/hls-services.service';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
    activeSlideIndex = 1;
    nextIndex = 2;
    prevIndex = 0;
    services: any;
    socialMedia = ['facebook', 'linkedin', 'youtube', 'xing', 'twitter', 'instagram'];

    constructor(private hlsServices: HlsServicesService) {
        hlsServices.getServices().subscribe((data) => {
            this.services = data.serviceSpotlights;
        });
    }

    ngOnInit(): void {}

    showCardAsCurrent(index: number): void {
        this.activeSlideIndex = index;
        this.prevIndex = this.activeSlideIndex - 1;
        this.nextIndex = this.activeSlideIndex + 1;

        if (this.activeSlideIndex === 0) {
            this.prevIndex = this.services.length - 1;
            this.nextIndex = this.activeSlideIndex + 1;
        }

        if (this.activeSlideIndex === this.services.length - 1) {
            this.prevIndex = this.activeSlideIndex - 1;
            this.nextIndex = 0;
        }
    }
}
