<nav class="navbar navbar-expand-lg navbar-default navbar-static-top">
    <div class="container-fluid p-0 d-flex h-100">
        <div class="logo-header" routerLink=""></div>
        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            (click)="blockScroll()"
        >
            <div class="burger-menu" [class.menu-open]="menuOpen">
                <span class="icon-menu"></span>
            </div>
        </button>
        <div class="under-992" (click)="showMyHouseOfLabs()">
            <button class="btn btn-primary extend">My House of Labs</button>
        </div>
        <div class="collapse navbar-collapse">
            <div class="navbar-nav ml-auto d-flex align-items-center">
                <div class="nav-link">
                    <a class="menu-item" routerLink="/" >
                        <div
                            class="h-100 d-flex align-items-center"
                            routerLinkActive="active-text" [routerLinkActiveOptions]="{ exact: true}"
                        >
                            Home
                        </div>
                        <div routerLinkActive="active-bar" [routerLinkActiveOptions]="{ exact: true}"
                        ></div>
                    </a>
                </div>
                <div class="nav-link">
                    <a class="menu-item" routerLink="/locations">
                        <div
                            class="h-100 d-flex align-items-center"
                            routerLinkActive="active-text"
                        >
                            Locations
                        </div>
                        <div routerLinkActive="active-bar"></div>
                    </a>
                </div>

                <div class="nav-link">
                    <a class="menu-item" routerLink="/community">
                        <div
                            class="h-100 d-flex align-items-center"
                            routerLinkActive="active-text"
                        >
                            Community
                        </div>
                        <div routerLinkActive="active-bar"></div>
                    </a>
                </div>

                <div class="nav-link">
                    <a class="menu-item" routerLink="/our-services">
                        <div
                            class="h-100 d-flex align-items-center"
                            routerLinkActive="active-text"
                        >
                            Our services
                        </div>
                        <div routerLinkActive="active-bar"></div>
                    </a>
                </div>

                <div class="nav-link">
                    <a class="menu-item" routerLink="/lab-science-academy">
                        <div
                            class="h-100 d-flex align-items-center"
                            routerLinkActive="active-text"
                        >
                            Lab Science Academy
                        </div>
                        <div routerLinkActive="active-bar"></div>
                    </a>
                </div>

                <div class="nav-link">
                    <a class="menu-item" routerLink="/media">
                        <div
                            class="h-100 d-flex align-items-center"
                            routerLinkActive="active-text"
                        >
                            Media
                        </div>
                        <div routerLinkActive="active-bar"></div>
                    </a>
                </div>

                <div class="nav-link">
                    <a class="menu-item" routerLink="/contact">
                        <div
                            class="h-100 d-flex align-items-center"
                            routerLinkActive="active-text"
                        >
                            Contact
                        </div>
                        <div routerLinkActive="active-bar"></div>
                    </a>
                </div>
            </div>
        </div>
        <div class="dropdown-menu" id="navbarNav">
            <a
                class="dropdown-item"
                routerLink="/"
                routerLinkActive="active-bar"
                [routerLinkActiveOptions]="{ exact: true}"
                >Home</a
            >
            <a
                class="dropdown-item"
                routerLink="/locations"
                routerLinkActive="active-bar"
            >Locations</a
            >
            <a
                class="dropdown-item"
                routerLink="/community"
                routerLinkActive="active-bar"
                >Community</a
            >
            <a
                class="dropdown-item"
                routerLink="/our-services"
                routerLinkActive="active-bar"
                >Our services</a
            >
            <a
                class="dropdown-item"
                routerLink="/lab-science-academy"
                routerLinkActive="active-bar"
            >Lab Science Academy</a
            >
            <a
                class="dropdown-item"
                routerLink="/media"
                routerLinkActive="active-bar"
                >Media</a
            >
            <a
                class="dropdown-item"
                routerLink="/contact"
                routerLinkActive="active-bar"
                >Contact</a
            >
            <div class="dropdown-item hide" (click)="showMyHouseOfLabs()">
                <div class="join-button">
                    <span>My House of Labs</span>
                </div>
            </div>
        </div>
        <div class="over-992" (click)="showMyHouseOfLabs()">
            <button class="btn btn-primary extend">My House of Labs</button>
        </div>
    </div>
</nav>
<app-comming-soon
    *ngIf="displayCommingSoon"
    (closeModal)="showMyHouseOfLabs()"
></app-comming-soon>
