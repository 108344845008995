import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeComponent } from '../subscribe/subscribe.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    constructor(private dialog: MatDialog) {}

    ngOnInit(): void {}

    openSubscribe(): void {
        this.dialog.open(SubscribeComponent);
    }
    toRegister(): void{
        window.location.href = 'user/register';
    }
}
