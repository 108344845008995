import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    menuOpen: boolean;
    displayCommingSoon: boolean;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                document.body.style.position = 'relative';
            }
        });
    }

    showMyHouseOfLabs(): void {
        window.location.href = '/dashboard/home';
        // if (environment.production) {
        //     const menu = document.getElementsByClassName('dropdown-menu');
        //     menu[0].classList.remove('show');

        //     if (!this.displayCommingSoon) {
        //         this.menuOpen = false;
        //     }

        //     this.displayCommingSoon = !this.displayCommingSoon;
        // } else {
        //     window.location.href = '/dashboard/home';
        // }
    }

    blockScroll(): void {
        this.menuOpen = !this.menuOpen;
    }
}
