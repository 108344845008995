import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SubscribeService } from 'src/app/services/subscribe.service';

@Component({
    selector: 'app-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
    subscribeForm: FormGroup;
    formSubmitted: boolean;

    constructor(
        private dialogRef: MatDialogRef<SubscribeComponent>,
        private formBuilder: FormBuilder,
        private subscribeService: SubscribeService,
        private toastrService: ToastrService
    ) {}

    ngOnInit(): void {
        this.subscribeForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            tellUsAboutYourself: ['', Validators.required],
            jobTitle: [''],
            companyName: [''],
            termsAndConditions: [false, Validators.requiredTrue],
        });
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    subscribeToNewsletter(): void {
        this.subscribeService
            .subscribeToNotifications(this.subscribeForm.value)
            .subscribe((res: { result: string; msg: string }) => {
                if (res.result === 'success') {
                    this.dialogRef.close();
                    this.toastrService.success('Thank you for subscribing');
                    return;
                }

                if (res.msg.includes('is already subscribed to list')) {
                    this.toastrService.error(
                        'You are already subscribed with this email'
                    );
                } else {
                    this.toastrService.error(
                        'We encountered a problem and couldn’t compete the task. Please reach out to info@houseoflabscience.world'
                    );
                }

                return;
            });
    }
}
