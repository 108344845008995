<div class="subscribe-modal-container">
    <div class="header">
        <div class="icon-close" (click)="closeModal()"></div>
        <div class="title">House of Lab Science Newsletter</div>
    </div>
    <form class="form-container" [formGroup]="subscribeForm">
        <div class="title">Subscribe for the latest updates and offers</div>
        <app-form-input
            [formControl]="subscribeForm.controls.firstName"
            [isRequired]="true"
            [submitted]="formSubmitted"
            placeholder="First name"
        ></app-form-input>
        <app-form-input
            [formControl]="subscribeForm.controls.lastName"
            [isRequired]="true"
            [submitted]="formSubmitted"
            placeholder="Last name"
        ></app-form-input>
        <app-form-input
            [formControl]="subscribeForm.controls.email"
            [isRequired]="true"
            [submitted]="formSubmitted"
            placeholder="Email"
        ></app-form-input>
        <mat-form-field [floatLabel]="'always'">
            <mat-label
                >Tell us about yourself
                <span class="required-mark">*</span></mat-label
            >
            <mat-select formControlName="tellUsAboutYourself">
                <mat-option value="Prospective tenant"
                    >Prospective tenant</mat-option
                >
                <mat-option value="Freelancer">Freelancer</mat-option>
                <mat-option value="Startup">Startup</mat-option>
                <mat-option value="Scaleup">Scaleup</mat-option>
                <mat-option value="SME/KMU">SME/KMU</mat-option>
            </mat-select>
        </mat-form-field>
        <app-form-input
            [formControl]="subscribeForm.controls.jobTitle"
            [submitted]="formSubmitted"
            placeholder="Job title / Role"
        ></app-form-input>
        <app-form-input
            [formControl]="subscribeForm.controls.companyName"
            [submitted]="formSubmitted"
            placeholder="Company name"
        ></app-form-input>
        <label class="checkbox-container">
            <input type="checkbox" formControlName="termsAndConditions" />
            <span class="checkmark"></span>
            <div class="checkbox-label">
                I agree with the
                <a routerLink="/terms-and-conditions" target="_blank"
                    ><span>Terms & Conditions</span></a
                >
                and
                <a routerLink="/privacy-policy" target="_blank"
                    ><span>Privacy Policy</span></a
                >
            </div>
        </label>
        <button
            class="subscribe-button"
            [class.disabled]="!subscribeForm.valid"
            (click)="subscribeForm.valid && subscribeToNewsletter()"
        >
            Subscribe
        </button>
    </form>
</div>
