import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/services/user.service';
import { MustMatch } from 'src/app/validators/must-match.validator';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { encryptPassword } from '../../../../shared/shared/utils/utils';

const passwordPattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/;

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    passwordSet = false;
    form: FormGroup;
    token: string;
    publicKey: string;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute
    ) {
        this.token = null;
        this.route.queryParams.subscribe((params) => {
            this.token = params.token;
        });
    }

    ngOnInit(): void {
        this.userService.getPublicKey().subscribe((response) => {
            this.publicKey = response.key;
        });

        if (this.token) {
            this.form = new FormGroup(
                {
                    password: new FormControl('', [
                        Validators.required,
                        Validators.pattern(passwordPattern),
                    ]),
                    confirmPassword: new FormControl('', Validators.required),
                },
                MustMatch('password', 'confirmPassword')
            );
        } else {
            this.form = new FormGroup(
                {
                    password: new FormControl('', [
                        Validators.required,
                        Validators.pattern(passwordPattern),
                    ]),
                    currentPassword: new FormControl('', [
                        Validators.required,
                        Validators.pattern(passwordPattern),
                    ]),
                    confirmPassword: new FormControl('', Validators.required),
                },
                MustMatch('password', 'confirmPassword')
            );
        }
    }

    send(): void {
        let useService: Observable<any>;
        const encodedPassword = encryptPassword(
            this.form.value.password,
            this.publicKey
        );
        const encodedCurrentPassword = encryptPassword(
            this.form.value.currentPassword,
            this.publicKey
        );

        if (this.token) {
            useService = this.userService.setPassword(
                encodedPassword,
                this.token
            );
        } else {
            useService = this.userService.resetPassword(
                encodedCurrentPassword,
                encodedPassword
            );
        }

        useService.subscribe(
            () => {
                console.log(3);
                this.passwordSet = true;
            },
            (error) => {
                if (error.status === 403) {
                    this.form.setErrors({ invalidToken: true });
                }
            }
        );
    }
}
