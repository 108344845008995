<app-page-layout>
    <div pageContent class="terms-and-conditions-container">
        <div class="image-container">
            <img src="../../../assets/IMG/building-terms.webp" />
        </div>
        <div class="h-title">Terms and conditions</div>
        <div class="text">
            These terms and conditions (“Agreement”) set forth the general terms
            and conditions of your use of the
            <a href="https://www.houseoflabscience.world" target="_blank"
                ><span>houseoflabscience.world</span></a
            >
            website (“Website” or “Service”) and any of its related products and
            services (collectively, “Services”). This Agreement is legally
            binding between you (“User”, “you” or “your”) and House of Lab
            Science AG (“House of Lab Science AG”, “we”, “us” or “our”). If you
            are entering into this agreement on behalf of a business or other
            legal entity, you represent that you have the authority to bind such
            entity to this agreement, in which case the terms “User”, “you” or
            “your” shall refer to such entity. If you do not have such
            authority, or if you do not agree with the terms of this agreement,
            you must not accept this agreement and may not access and use the
            Website and Services. By accessing and using the Website and
            Services, you acknowledge that you have read, understood, and agree
            to be bound by the terms of this Agreement. You acknowledge that
            this Agreement is a contract between you and House of Lab Science
            AG, even though it is electronic and is not physically signed by
            you, and it governs your use of the Website and Services.
        </div>

        <div class="title">Accounts and membership</div>
        <div class="text">
            If you create an account on the Website, you are responsible for
            maintaining the security of your account and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with it. We may monitor and review
            new accounts before you may sign in and start using the Services.
            Providing false contact information of any kind may result in the
            termination of your account. You must immediately notify us of any
            unauthorized uses of your account or any other breaches of security.
            We will not be liable for any acts or omissions by you, including
            any damages of any kind incurred as a result of such acts or
            omissions. We may suspend, disable, or delete your account (or any
            part thereof) if we determine that you have violated any provision
            of this Agreement or that your conduct or content would tend to
            damage our reputation and goodwill. If we delete your account for
            the foregoing reasons, you may not re-register for our Services. We
            may block your email address and Internet protocol address to
            prevent further registration.
        </div>

        <div class="title">Billing and payments</div>
        <div class="text">
            You shall pay all fees or charges to your account in accordance with
            the fees, charges, and billing terms in effect at the time a fee or
            charge is due and payable. If auto-renewal is enabled for the
            Services you have subscribed for, you will be charged automatically
            in accordance with the term you selected. If, in our judgment, your
            purchase constitutes a high-risk transaction, we will require you to
            provide us with a copy of your valid government-issued photo
            identification, and possibly a copy of a recent bank statement for
            the credit or debit card used for the purchase. We reserve the right
            to change products and product pricing at any time. We also reserve
            the right to refuse any order you place with us. We may, in our sole
            discretion, limit or cancel quantities purchased per person, per
            household or per order. These restrictions may include orders placed
            by or under the same customer account, the same credit card, and/or
            orders that use the same billing and/or shipping address. In the
            event that we make a change to or cancel an order, we may attempt to
            notify you by contacting the e-mail and/or billing address/phone
            number provided at the time the order was made.
        </div>

        <div class="title">Accuracy of information</div>
        <div class="text">
            Occasionally there may be information on the Website that contains
            typographical errors, inaccuracies or omissions that may relate to
            availability, promotions and offers. We reserve the right to correct
            any errors, inaccuracies or omissions, and to change or update
            information or cancel orders if any information on the Website or
            Services is inaccurate at any time without prior notice (including
            after you have submitted your order). We undertake no obligation to
            update, amend or clarify information on the Website including,
            without limitation, pricing information, except as required by law.
            No specified update or refresh date applied on the Website should be
            taken to indicate that all information on the Website or Services
            has been modified or updated.
        </div>

        <div class="title">Links to other resources</div>
        <div class="text">
            Although the Website and Services may link to other resources (such
            as websites, mobile applications, etc.), we are not, directly or
            indirectly, implying any approval, association, sponsorship,
            endorsement, or affiliation with any linked resource, unless
            specifically stated herein. Some of the links on the Website may be
            “affiliate links”. This means if you click on the link and purchase
            an item, House of Lab Science AG will receive an affiliate
            commission. We are not responsible for examining or evaluating, and
            we do not warrant the offerings of, any businesses or individuals or
            the content of their resources. We do not assume any responsibility
            or liability for the actions, products, services, and content of any
            other third parties. You should carefully review the legal
            statements and other conditions of use of any resource which you
            access through a link on the Website. Your linking to any other
            off-site resources is at your own risk.
        </div>

        <div class="title">Prohibited uses</div>
        <div class="text">
            In addition to other terms as set forth in the Agreement, you are
            prohibited from using the Website and Services or Content: (a) for
            any unlawful purpose; (b) to solicit others to perform or
            participate in any unlawful acts; (c) to violate any international,
            federal, provincial or state regulations, rules, laws, or local
            ordinances; (d) to infringe upon or violate our intellectual
            property rights or the intellectual property rights of others; (e)
            to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability; (f)
            to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used
            in any way that will affect the functionality or operation of the
            Website and Services, third party products and services, or the
            Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or
            scrape; (i) for any obscene or immoral purpose; or (j) to interfere
            with or circumvent the security features of the Website and
            Services, third party products and services, or the Internet. We
            reserve the right to terminate your use of the Website and Services
            for violating any of the prohibited uses.
        </div>

        <div class="title">Intellectual property rights</div>
        <div class="text">
            “Intellectual Property Rights” means all present and future rights
            conferred by statute, common law or equity in or in relation to any
            copyright and related rights, trademarks, designs, patents,
            inventions, goodwill and the right to sue for passing off, rights to
            inventions, rights to use, and all other intellectual property
            rights, in each case whether registered or unregistered and
            including all applications and rights to apply for and be granted,
            rights to claim priority from, such rights and all similar or
            equivalent rights or forms of protection and any other results of
            intellectual activity which subsist or will subsist now or in the
            future in any part of the world. This Agreement does not transfer to
            you any intellectual property owned by House of Lab Science AG or
            third parties, and all rights, titles, and interests in and to such
            property will remain (as between the parties) solely with House of
            Lab Science AG. All trademarks, service marks, graphics and logos
            used in connection with the Website and Services, are trademarks or
            registered trademarks of House of Lab Science AG or its licensors.
            Other trademarks, service marks, graphics and logos used in
            connection with the Website and Services may be the trademarks of
            other third parties. Your use of the Website and Services grants you
            no right or license to reproduce or otherwise use any of House of
            Lab Science AG or third party trademarks.
        </div>

        <div class="title">Limitation of liability</div>
        <div class="text">
            To the fullest extent permitted by applicable law, in no event will
            House of Lab Science AG, its affiliates, directors, officers,
            employees, agents, suppliers or licensors be liable to any person
            for any indirect, incidental, special, punitive, cover or
            consequential damages (including, without limitation, damages for
            lost profits, revenue, sales, goodwill, use of content, impact on
            business, business interruption, loss of anticipated savings, loss
            of business opportunity) however caused, under any theory of
            liability, including, without limitation, contract, tort, warranty,
            breach of statutory duty, negligence or otherwise, even if the
            liable party has been advised as to the possibility of such damages
            or could have foreseen such damages. To the maximum extent permitted
            by applicable law, the aggregate liability of House of Lab Science
            AG and its affiliates, officers, employees, agents, suppliers and
            licensors relating to the services will be limited to an amount no
            greater than one dollar or any amounts actually paid in cash by you
            to House of Lab Science AG for the prior one month period prior to
            the first event or occurrence giving rise to such liability. The
            limitations and exclusions also apply if this remedy does not fully
            compensate you for any losses or fails of its essential purpose.
        </div>

        <div class="title">Indemnification</div>
        <div class="text">
            You agree to indemnify and hold House of Lab Science AG and its
            affiliates, directors, officers, employees, agents, suppliers and
            licensors harmless from and against any liabilities, losses, damages
            or costs, including reasonable attorneys’ fees, incurred in
            connection with or arising from any third party allegations, claims,
            actions, disputes, or demands asserted against any of them as a
            result of or relating to your Content, your use of the Website and
            Services or any willful misconduct on your part.
        </div>

        <div class="title">Severability</div>
        <div class="text">
            All rights and restrictions contained in this Agreement may be
            exercised and shall be applicable and binding only to the extent
            that they do not violate any applicable laws and are intended to be
            limited to the extent necessary so that they will not render this
            Agreement illegal, invalid or unenforceable. If any provision or
            portion of any provision of this Agreement shall be held to be
            illegal, invalid or unenforceable by a court of competent
            jurisdiction, it is the intention of the parties that the remaining
            provisions or portions thereof shall constitute their agreement with
            respect to the subject matter hereof, and all such remaining
            provisions or portions thereof shall remain in full force and
            effect.
        </div>

        <div class="title">Changes and amendments</div>
        <div class="text">
            We reserve the right to modify this Agreement or its terms related
            to the Website and Services at any time at our discretion. When we
            do, we will revise the updated date at the bottom of this page. We
            may also provide notice to you in other ways at our discretion, such
            as through the contact information you have provided.
        </div>
        <div class="text">
            An updated version of this Agreement will be effective immediately
            upon the posting of the revised Agreement unless otherwise
            specified. Your continued use of the Website and Services after the
            effective date of the revised Agreement (or such other act specified
            at that time) will constitute your consent to those changes.
        </div>

        <div class="title">Acceptance of these terms</div>
        <div class="text">
            You acknowledge that you have read this Agreement and agree to all
            its terms and conditions. By accessing and using the Website and
            Services you agree to be bound by this Agreement. If you do not
            agree to abide by the terms of this Agreement, you are not
            authorized to access or use the Website and Services.
        </div>

        <div class="title">Contacting us</div>
        <div class="text">
            If you have any questions, concerns, or complaints regarding this
            Agreement, we encourage you to contact us using the details below:
        </div>
        <div class="text">
            <a href="mailto:info@houseoflabscience.world" target="_blank"
                ><span>info@houseoflabscience.world</span></a
            >
        </div>
        <div class="text">
            This document was last updated on November 4, 2021
        </div>
    </div>
</app-page-layout>
