import {Component, ElementRef, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

    private observer: IntersectionObserver;

    constructor(public element: ElementRef, @Inject(PLATFORM_ID) private platformId: any, private titleService: Title) {}
  hombrechtikonImages = [
      '../../../assets/IMG/location/Hombrechtikon 1.webp',
      '../../../assets/IMG/location/Hombrechtikon 2.webp',
      '../../../assets/IMG/location/Hombrechtikon 3.webp',
  ];
    kemptthalImages = [
        '../../../assets/IMG/location/Kemptthal, The Valley 1.webp',
        '../../../assets/IMG/location/Kemptthal, The Valley 2.webp',
        '../../../assets/IMG/location/Kemptthal, The Valley 3.webp',

    ];
    baselImages = [
        '../../../assets/IMG/location/Basel, Werkarena 1.webp',
        '../../../assets/IMG/location/Basel, Werkarena 2.webp',
        '../../../assets/IMG/location/Basel, Werkarena 3.webp',
    ];
  ngOnInit(): void {
      if (isPlatformBrowser(this.platformId)) {
          this.subscribeElementView();
      }
      this.titleService.setTitle('Our innovation parks locations | HLS');
  }

    private handleIntersect(entries): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
            }
            else{
                entry.target.classList.remove('active');
            }
        });
    }

    private subscribeElementView(): void {
        this.destroyObserver();
        this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) =>
        {
            this.handleIntersect(entries);
        });
        const animationElements = document.querySelectorAll('.animation');
        animationElements.forEach((el) => this.observer.observe(el));
    }
    private destroyObserver(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

}
