import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['./form-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormInputComponent),
            multi: true,
        },
    ],
})
export class FormInputComponent implements OnInit, ControlValueAccessor {
    @Input() formControl: FormControl;
    @Input() placeholder: string;
    @Input() isTextarea: boolean;
    @Input() isRequired: boolean;
    @Input() showForgotPassword: boolean;
    @Input() inputType: string;
    @Input() showIcon: string;
    @Input() isPasswordInput: boolean;
    @Input() rows: number;
    @Input() isLowerCase: boolean;
    @Input() set submitted(submit: boolean) {
        if (submit) {
            this.formControl.markAsDirty();
            this.formControl.markAsTouched();
            this.formControl.updateValueAndValidity();
        }
    }
    @Output() fieldLeave: EventEmitter<void> = new EventEmitter<void>();

    inputValue = '';

    onChange: any = () => {};
    onTouch: any = () => {};

    constructor() {}

    ngOnInit(): void {}

    set value(val: string | undefined) {
        if (val !== undefined && this.inputValue !== val) {
            this.inputValue = val;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    writeValue(value: string | undefined): void {
        this.value = value;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    getFormControlError(): string {
        if (this.formControl.errors?.required) {
            return this.placeholder + ' is required.';
        }

        if (this.formControl.errors?.email) {
            return this.placeholder + ' does not have the right format.';
        }

        if (this.formControl.errors?.maxlength) {
            return (
                this.placeholder +
                ` cannot have more than ${this.formControl.errors?.maxlength.requiredLength} characters.`
            );
        }

        if (this.formControl.errors?.userExists) {
            return 'Username: ' + this.formControl.value + ' already exists.';
        }

        return '';
    }

    changeInputType(): void {
        if (this.inputType === 'password') {
            this.inputType = 'text';
            return;
        }

        if (this.inputType === 'text') {
            this.inputType = 'password';
            return;
        }
    }

    onBlur(): void {
        this.fieldLeave.emit();
    }
}
