import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSubscriptionModel } from 'projects/shared-models/src/lib/user-subscription.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../../../../../projects/shared-models/src/lib/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    userUrl = environment.apiUrl + '/user';
    private user: UserModel;

    constructor(private http: HttpClient) {}

    registerUser(user: any, file?: File): Observable<any> {
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        }
        formData.append('user', JSON.stringify(user));

        return this.http.post(this.userUrl, formData);
    }

    resendEmail(userId: string): Observable<{}> {
        return this.http.get(`${this.userUrl}/${userId}/confirmation-email`);
    }

    checkUsername(username: string): Observable<{}> {
        return this.http.get(`${this.userUrl}/${username}/check-username`);
    }

    checkPayment(userId: string): Observable<any> {
        return this.http.get(`${this.userUrl}/${userId}/is-payment-done`);
    }

    getLoggedInUser(): Observable<UserModel> {
        return this.http.get<UserModel>(`${this.userUrl}`);
    }

    setLoggedUser(user: UserModel): void {
        this.user = user;
        localStorage.setItem('logged_user', JSON.stringify(user));
    }

    getUser(): UserModel {
        return (
            this.user ||
            (JSON.parse(localStorage.getItem('logged_user')) as UserModel)
        );
    }

    getOrganisationList(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/organisation/names`);
    }

    updateUser(user: any, file?: File): Observable<any> {
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        }
        formData.append('user', JSON.stringify(user));
        return this.http.put(`${this.userUrl}/${this.user.id}`, formData);
    }

    forgotPassword(username: string): Observable<{}> {
        return this.http.get(`${this.userUrl}/${username}/forgotten-password`);
    }

    setPassword(newPassword: string, token: string): Observable<{}> {
        return this.http.post(`${this.userUrl}/set-password`, {
            newPassword,
            token,
        });
    }

    resetPassword(
        currentPassword: string,
        newPassword: string
    ): Observable<{}> {
        return this.http.post(`${this.userUrl}/reset-password`, {
            currentPassword,
            newPassword,
        });
    }

    deleteAccount(): Observable<{}> {
        return this.http.delete(`${this.userUrl}/${this.user.id}`);
    }

    getPublicKey(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/key`);
    }

    getSubscription(): Observable<UserSubscriptionModel> {
        return this.http.get<UserSubscriptionModel>(
            environment.apiUrl + '/subscription'
        );
    }

    cancelSubscription() {
        return this.http.put(environment.apiUrl + '/subscription/cancel', '');
    }
}
