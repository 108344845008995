<app-page-layout>
    <div pageContent class="our-services-container">
        <div class="video-countdown-container">
            <div class="text-container">
                <div class="our-service-header-title">
                    We’re currently constructing YOUR future lab space.
                </div>
                <div class="our-service-header-info">
                    <div>
                        The first construction phase will be completed in April
                        2022. Almost 1000 square meters of new laboratory rooms,
                        ready to be used, will be available.
                    </div>
                    <div class="mt-12px">
                        Additional 800 square meters of new laboratory rooms are
                        to be built in the following year and will be ready at a
                        later date.
                    </div>
                </div>
            </div>
            <div class="video">
                <video controls>
                    <source
                        src="../../../assets/IMG/our-service/our-service.mp4"
                        type="video/mp4"
                    />
                </video>
                <div class="video-placeholder">
                    If interested, contact us at info@houseoflabscience.world
                </div>
            </div>

            <div class="countdown-container">
                <div class="countdown-title">
                    My House of Labs Online Community
                </div>
                <div class="countdown-time">
                    <div class="countdown-item">
                        <div class="time-label">Days</div>
                        <div class="value">{{ days | number: "2.0-0" }}</div>
                    </div>
                    <div class="countdown-item-dots">:</div>
                    <div class="countdown-item">
                        <div class="time-label">Hours</div>
                        <div class="value">{{ hours | number: "2.0-0" }}</div>
                    </div>
                    <div class="countdown-item-dots">:</div>
                    <div class="countdown-item">
                        <div class="time-label">Minutes</div>
                        <div class="value">{{ minutes | number: "2.0-0" }}</div>
                    </div>
                    <div class="countdown-item-dots">:</div>
                    <div class="countdown-item">
                        <div class="time-label">Seconds</div>
                        <div class="value">{{ seconds | number: "2.0-0" }}</div>
                    </div>
                </div>
                <div class="subscribe-container">
                    <div class="info-text">Subscribe to our newsletter</div>
                    <button
                        class="subscribe-button"
                        (click)="subscribeToNewsletter()"
                    >
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
        <div class="spotlight-services">
            <app-carousel></app-carousel>
        </div>

        <div class="hls-services">
            <div class="title">
                <div>HLS Services</div>
                <div class="delimiter"></div>
            </div>

            <button
                class="download-services"
                (click)="downloadServicesCatalog()"
            >
                <div class="icon-download"></div>
                <div>Download services catalogue</div>
            </button>

            <div class="services-grid">
                <div class="grid-header">
                    <div class="title">Service</div>
                    <div class="title">
                        Pricing for HLS<br />community<br />members
                    </div>
                    <div class="title">
                        Pricing for HLS non-community members
                    </div>
                    <div class="title">
                        Who is paying<br />
                        for the<br />
                        service
                    </div>
                    <div class="title">Billing</div>
                </div>

                <div
                    [class]="serviceType"
                    *ngFor="let serviceType of servicesCategory"
                >
                    <div class="grid-label">
                        <img
                            [src]="
                                '../../../assets/IMG/our-service/' +
                                serviceType +
                                '.svg'
                            "
                        />{{ serviceType | hlsServiceCategory }}
                    </div>

                    <div
                        class="grid-section"
                        *ngFor="let availability of availabilityList"
                    >
                        <div
                            class="grid-vertical-label on-site"
                            *ngIf="
                                services?.services[availability][serviceType] &&
                                availability == 'ON_SITE'
                            "
                        >
                            <mat-icon class="icon"> place_outline </mat-icon>
                            On site
                        </div>
                        <div
                            class="grid-vertical-label online"
                            *ngIf="
                                services?.services[availability][serviceType] &&
                                availability == 'ONLINE'
                            "
                        >
                            <mat-icon class="icon"> wifi </mat-icon>Online
                        </div>
                        <div class="grid-content">
                            <div
                                class="grid-row"
                                [class.blue]="availability == 'ONLINE'"
                                *ngFor="
                                    let service of services?.services[
                                        availability
                                    ][serviceType]
                                "
                            >
                                <div
                                    class="grid-item"
                                    [innerHTML]="service.description"
                                ></div>
                                <div class="grid-item">
                                    <div class="discount-wrap">
                                        <div class="discount">
                                            {{ service.discount }}
                                        </div>
                                        discount
                                    </div>
                                    <div class="cell-price">
                                        <div class="currency">
                                            {{
                                                service.hlsPricing.split(" ")[0]
                                            }}
                                        </div>
                                        <div
                                            class="price"
                                            [class.free-text]="
                                                service.nonHlsPricing.includes(
                                                    'n/a'
                                                )
                                            "
                                        >
                                            {{
                                                service.hlsPricing.split(" ")[1]
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-item">
                                    <div
                                        class="cell-price"
                                        [class.free-text]="
                                            service.nonHlsPricing.includes(
                                                'n/a'
                                            )
                                        "
                                    >
                                        <div class="currency">
                                            {{
                                                service.nonHlsPricing.split(
                                                    " "
                                                )[0]
                                            }}
                                        </div>
                                        <div class="price">
                                            {{
                                                service.nonHlsPricing.split(
                                                    " "
                                                )[1]
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-item">
                                    <div
                                        class="cell-billing"
                                        [class.double]="
                                            service?.organization &&
                                            service?.individual
                                        "
                                    >
                                        <div
                                            class="org"
                                            *ngIf="service?.organization"
                                        >
                                            Organization
                                        </div>
                                        <div
                                            class="indiv"
                                            *ngIf="service?.individual"
                                        >
                                            Individual
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-item">
                                    {{ service.billing }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="services-cards">
                <div
                    [class]="serviceType"
                    *ngFor="let serviceType of servicesCategory"
                >
                    <div class="grid-label">
                        <img
                            [src]="
                                '../../../assets/IMG/our-service/' +
                                serviceType +
                                '.svg'
                            "
                        />
                        {{ serviceType | hlsServiceCategory }}
                    </div>
                    <div *ngFor="let availability of availabilityList">
                        <div
                            class="service-card"
                            *ngFor="
                                let service of services?.services[availability][
                                    serviceType
                                ]
                            "
                        >
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <div>
                                    <div
                                        class="card-icon"
                                        [ngClass]="availability"
                                    >
                                        <img
                                            [src]="
                                                '../../../assets/IMG/our-service/' +
                                                serviceType +
                                                '_card.svg'
                                            "
                                        />
                                        <div *ngIf="availability == 'ONLINE'">
                                            <mat-icon class="icon">
                                                wifi
                                            </mat-icon>
                                            Online
                                        </div>
                                        <div *ngIf="availability == 'ON_SITE'">
                                            <mat-icon class="icon">
                                                place_outline
                                            </mat-icon>
                                            On site
                                        </div>
                                    </div>
                                    <div class="card-price">
                                        <div class="currency">
                                            {{
                                                service.nonHlsPricing.split(
                                                    " "
                                                )[0]
                                            }}
                                        </div>
                                        <div class="price">
                                            {{
                                                service.nonHlsPricing.split(
                                                    " "
                                                )[1]
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="discount-wrap">
                                    <div class="discount">
                                        {{ service.discount }}
                                    </div>
                                    HLS member
                                </div>
                            </div>
                            <div class="card-paying">
                                {{ service.billing }}
                            </div>
                            <div class="card-billing">
                                <div class="org" *ngIf="service?.organization">
                                    Organization
                                </div>
                                <div class="indiv" *ngIf="service?.individual">
                                    Individual
                                </div>
                            </div>
                            <div class="card-billing-text">
                                Who is paying for the service
                            </div>
                            <div [ngClass]="serviceType">
                                <div class="divider"></div>
                                <div>
                                    <div class="card-description-title">
                                        {{ serviceType | hlsServiceCategory }}
                                        service
                                    </div>
                                </div>
                            </div>
                            <div
                                clas="card-description-text"
                                [innerHTML]="service.description"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact">
                For bookings or questions about our services, send us an email
                at
                <a href="mailto:info@houseoflabscience.world"
                    >info@houseoflabscience.world</a
                >
            </div>
        </div>
    </div>
</app-page-layout>
