import { Injectable } from '@angular/core';
import {
    Router,
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserModel } from '../../../projects/shared-models/src/lib/user.model';
import { UserService } from '../modules/user/services/user.service';
import { tap } from 'rxjs/operators';
import { SubscriptionEnum } from 'projects/shared-models/src/lib/enums/subscription.enum';

@Injectable({
    providedIn: 'root',
})
export class UserResolver implements Resolve<boolean> {
    constructor(private userService: UserService, private router: Router) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return this.userService.getLoggedInUser().pipe(
            tap(
                (user: UserModel) => {
                    this.userService.setLoggedUser(user);
                    if ((user.userType !== 'ORGANIZATION' && user.subscription.subscriptionStatus === SubscriptionEnum.NOT_SUBSCRIBED) ||
                        (user.userType === 'ORGANIZATION' && user.subscription.subscriptionEndDate !== null &&
                            new Date(user.subscription.subscriptionEndDate).getTime() < Date.now())) {
                        this.router.navigate(['/payments']);
                    }
                    return true;
                },
                () => {
                    return false;
                }
            )
        );
    }
}
