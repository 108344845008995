<div class="comming-soon-container">
    <div class="comming-soon-card">
        <div class="icon-close" (click)="onCloseModal()"></div>
        <div class="header">
            <div class="small-text">My House of Labs</div>
            <div class="big-text">Coming soon!</div>
        </div>
        <div class="countdown" style="display: none !important">
            <div class="countdown-time">
                <div class="countdown-item">
                    <div class="time-label">Days</div>
                    <div class="value">{{ days | number: "2.0-0" }}</div>
                </div>
                <div class="countdown-item-dots">:</div>
                <div class="countdown-item">
                    <div class="time-label">Hours</div>
                    <div class="value">{{ hours | number: "2.0-0" }}</div>
                </div>
                <div class="countdown-item-dots">:</div>
                <div class="countdown-item">
                    <div class="time-label">Minutes</div>
                    <div class="value">{{ minutes | number: "2.0-0" }}</div>
                </div>
                <div class="countdown-item-dots">:</div>
                <div class="countdown-item">
                    <div class="time-label">Seconds</div>
                    <div class="value">{{ seconds | number: "2.0-0" }}</div>
                </div>
            </div>
        </div>
        <div class="subscribe">
            <div class="info-text">Subscribe to our newsletter</div>
            <button class="subscribe-button" (click)="subscribeToNewsletter()">
                Subscribe
            </button>
        </div>
    </div>
</div>
