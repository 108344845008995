export const environment = {
    production: true,
    apiUrl: 'https://houseoflabscience.world/api',
    keycloak: {
        url: 'https://houseoflabscience.world/auth/',
        realm: 'hls',
        clientId: 'hls-angular',
        redirectUri: 'https://houseoflabscience.world/dashboard',
    },
};
