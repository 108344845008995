import {Component, ElementRef, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-lab-science-academy',
    templateUrl: './lab-science-academy.component.html',
    styleUrls: ['./lab-science-academy.component.scss']
})
export class LabScienceAcademyComponent implements OnInit {

    private observer: IntersectionObserver;

    constructor(public element: ElementRef, @Inject(PLATFORM_ID) private platformId: any, private titleService: Title) {
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.subscribeElementView();
        }
        this.titleService.setTitle('Join House of Lab Science Academy | HLS');
    }

    private subscribeElementView(): void {
        this.destroyObserver();
        this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            this.handleIntersect(entries);
        });
        const animationElements = document.querySelectorAll('.animation');
        animationElements.forEach((el) => this.observer.observe(el));
    }

    private handleIntersect(entries): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
            }
        });
    }

    private destroyObserver(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    toRegister() {

    }

    toDownload() {

    }
    scrollTo(elementId) {
        document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});
    }

}
