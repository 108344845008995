<app-page-layout>
    <div pageContent class="location-container">
        <div class="title-section">
            <div class="title animation">House of Lab Science</div>
            <div class="sub-title animation">A single point of contact for <br/>orchestrating innovation parks</div>
        </div>
        <div class="location-section">
            <div class="building animation">
                <img class="image-360" src="assets/IMG/location/Hombrechtikon%20360.webp">
                <img class="image-1440" src="assets/IMG/location/Hombrechtikon%201440.webp">
                <img class="image-1920" src="assets/IMG/location/Hombrechtikon%201920.webp">
            </div>
            <div class="title animation">Hombrechtikon</div>
            <div class="text animation">
                The first House of Lab Science is ready for you in Garstligweg 6 and 8 in Hombrechtikon,
                Switzerland. You will find the home bases of various leading laboratory technology providers
                such as HSE, Tecan and Mettler Toledo in the direct neighborhood.
            </div>
            <div class="location-images animation">
                <div *ngFor="let image of hombrechtikonImages" class="images">
                    <img [src]="image">
                </div>
            </div>
        </div>
        <div class="location-section second-section">
            <a href="https://www.thevalley.ch/de" target="_blank" class="title">Kemptthal, The Valley</a>
            <div class="text animation">
                Located in the trending foodtech cluster, home of Givaudan,
                Bühler, planted, among others, the House of Lab Science is
                thrilled to provide the foodtech and biotech innovators with
                the lab and collaboration space they need. Opening in 2025.
            </div>
            <div class="location-images animation">
                <div *ngFor="let image of kemptthalImages" class="images">
                    <img [src]="image">
                </div>
            </div>
            <div class="building animation">
                <img class="image-360" src="assets/IMG/location/Kemptthal,%20The%20Valley%20360.webp">
                <img class="image-1440" src="assets/IMG/location/Kemptthal,%20The%20Valley%201440.webp">
                <img class="image-1920" src="assets/IMG/location/Kemptthal,%20The%20Valley%201920.webp">
            </div>
        </div>
        <div class="location-section coming-soon">
            <div class="title animation">Zofingen, Siegfried campus</div>
            <div class="text animation">COMING SOON ... </div>
            <img src="assets/IMG/location/Location%20coming%20soon%20icon.svg" class="location-icon animation"/>
        </div>
    </div>
</app-page-layout>
