import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from 'projects/shared-models/src/public-api';

@Pipe({
    name: 'hlsServiceCategory',
})
export class HlsServiceCategoryPipe implements PipeTransform {
    static transform(category: any) {
        return 'Bla';
    }
    transform(category: string): string {
        switch (category) {
            case 'LAB_MANAGEMENT':
                return 'Lab management';
            case 'LAB_SUP_MILIAN_SA':
                return 'Lab supplies and consumables powered by Milian SA';
            case 'INNOVATION_MANAGEMENT':
                return 'Innovation management';
            case 'FACILITY_MANAGEMENT':
                return 'Facility management';
            case 'ICT_MANAGEMENT':
                return 'ICT management';
            case 'OTHER':
                return 'Other';
            default:
                return category;
        }
    }
}
