<mat-form-field
    [floatLabel]="'always'"
    [appearance]="'legacy'"
    [class.invalid]="
        (formControl.dirty || formControl.touched) && formControl.invalid
    "
>
    <mat-label> {{ placeholder }} <span *ngIf="isRequired">*</span></mat-label>
    <div class="d-flex">
        <div [ngClass]="showIcon" *ngIf="showIcon"></div>
        <input
            [class.lowercase]="isLowerCase"
            matInput
            *ngIf="!isTextarea"
            [formControl]="formControl"
            (blur)="onBlur()"
            [type]="inputType || 'text'"
        />
        <div
            *ngIf="isPasswordInput"
            (click)="changeInputType()"
            [ngClass]="
                inputType === 'password'
                    ? 'icon-password-hidden'
                    : 'icon-password-visible'
            "
        ></div>
    </div>
    <textarea
        *ngIf="isTextarea"
        [rows]="rows || 5"
        matInput
        [formControl]="formControl"
    ></textarea>
    <mat-error *ngIf="formControl.invalid">
        {{ getFormControlError() }}
    </mat-error>
    <a
        class="forgot-password"
        *ngIf="showForgotPassword"
        routerLink="/forgot-password"
        >Forgot your password?</a
    >
</mat-form-field>
