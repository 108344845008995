<app-page-layout>
    <div carouselImages class="image-carousel" *ngIf="showImageCarousel">
        <div class="icon-close" (click)="showImageCarousel = false"></div>
        <div id="aboutCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button
                    type="button"
                    data-bs-target="#aboutCarousel"
                    *ngFor="let imageUrl of carouselImages; let i = index"
                    [class.active]="i === activeSlideIndex"
                ></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item tumbnail" data-bs-interval="0">
                    <img [src]="carouselImages[prevIndex]"/>
                </div>
                <div
                    class="carousel-item"
                    *ngFor="let imageUrl of carouselImages; let i = index"
                    data-bs-interval="0"
                    [class.active]="i === activeSlideIndex"
                >
                    <img [src]="imageUrl"/>
                </div>
                <div class="carousel-item tumbnail" data-bs-interval="0">
                    <img [src]="carouselImages[nextIndex]"/>
                </div>
            </div>
            <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                (click)="prevSlide()"
                data-bs-slide="prev"
            >
                <span class="icon-prev" aria-hidden="true"></span>
            </button>
            <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                (click)="nextSlide()"
                data-bs-slide="next"
            >
                <span class="icon-next" aria-hidden="true"></span>
            </button>
        </div>
    </div>
    <div pageContent class="about-container">
        <div class="title-section">
            <div class="animation">Where lab and life sciences</div>
            <div class="animation">innovation and business are at home</div>
        </div>
        <div class="mission">
            <div class="title">our mission</div>
            <div class="text">We empower science-preneurs to focus on validating game-changing
                ideas, business growth and maximizing capital efficiency.
            </div>
        </div>
        <div class="offer">
            <div class="title">
                <span>our offer</span>
            </div>
            <div class="offer-content">
                <div class="service">
                    <div class="image lab-as-service animation">
                        <div class="service-title">
                            lab as a service
                        </div>
                    </div>
                    <div class="content-wrapper">
                        <div class="content content-title">
                            <div class="service-sub-title animation">
                                start innovating from day 1
                            </div>
                        </div>
                        <div class="content">
                            <div class="benefits animation">
                                <div class="benefits-title">ready-to-move in
                                    <div class="underline"></div>
                                </div>
                                <div class="benefits-sub-title">Affordable, fitted and well-equipped
                                    wet labs
                                </div>
                            </div>
                            <div class="benefits animation ">
                                <div class="benefits-title">biosafety certified
                                    <div class="underline"></div>
                                </div>
                                <div class="benefits-sub-title">BSL-1 and BSL-2 standard</div>
                            </div>
                            <div class="benefits animation">
                                <div class="benefits-title">shared facilities
                                    <div class="underline"></div>
                                </div>
                                <div class="benefits-sub-title">Storage, laboratory glassware
                                    washer, autoclave, etc.
                                </div>
                            </div>
                            <div class="benefits animation">
                                <div class="benefits-title">work spaces
                                    <div class="underline"></div>
                                </div>
                                <div class="benefits-sub-title">Offices, meeting rooms, events
                                    hall
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service reverse">
                    <div class="content-wrapper">
                        <div class="content content-title">
                            <div class="service-sub-title animation">
                                online community platform
                            </div>
                        </div>
                        <div class="content text-end">
                            <div class="benefits text-end animation">
                                <div class="benefits-title ">bring your science to life</div>
                                <div class="underline"></div>
                                <div class="benefits-sub-title ">10-50% discounts on lab equipment,
                                    supplies, and lab management services
                                </div>
                            </div>
                            <div class="benefits text-end animation">
                                <div class="benefits-title ">advance your business</div>
                                <div class="underline"></div>
                                <div class="benefits-sub-title ">10-100% discounts on
                                    startup-corporate
                                    pairing events, workshops, and innovation scouting services
                                </div>
                            </div>
                            <div class="benefits text-end animation">
                                <div class="benefits-title ">stay up to date</div>
                                <div class="underline"></div>
                                <div class="benefits-sub-title ">Exclusive weekly digests,
                                    interviews
                                    and industry insights, so that you are always informed about the
                                    latest and most important events in the industry
                                </div>
                            </div>
                            <div class="benefits text-end animation">
                                <div class="benefits-title ">seize career opportunities</div>
                                <div class="underline"></div>
                                <div class="benefits-sub-title ">Network directly by accessing our
                                    members directory, the job platform or further education
                                    programs
                                    (The Life Sciences Academy)
                                </div>
                            </div>
                            <div (click)="toRegister()" class="benefits join-button animation">
                                <span>Join Now</span>
                            </div>
                        </div>
                    </div>
                    <div class="image my-hls animation">
                        <div class="service-title">
                            My House of Labs
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="big-image"></div>
        <div class="out-team-header"></div>
        <div class="our-team">
            <div class="title">
                <div>Our Team</div>
                <div class="delimiter"></div>
            </div>

            <div class="team-members-white-layer">
                <div class="team-members">
                    <div class="member-card animation-our-team">
                        <div class="rounded-image">
                            <img
                                alt=""
                                src="../../../assets/IMG/ourteam/Our-team-Christian-Hugo-Hoffmann.webp"
                            />
                        </div>
                        <div class="member-name">
                            <div>christian hugo Hoffmann</div>
                            <div class="delimiter-pink-bar"></div>
                        </div>
                        <div class="occupation">
                            <div>CEO</div>
                            <div>CO-FOUNDER</div>
                            <div class="linkedin">
                                <a
                                    href="https://www.linkedin.com/in/dr-christian-hugo-hoffmann-117601100/"
                                    aria-label="hoffmann"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <div class="icon-linkedin"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="member-card animation-our-team">
                        <div class="rounded-image">
                            <img
                                alt=""
                                src="../../../assets/IMG/ourteam/Our-team-Oana-Vrabie.webp"
                            />
                        </div>
                        <div class="member-name">
                            <div>oana monica vrabie</div>
                            <div class="delimiter-pink-bar"></div>
                        </div>
                        <div class="occupation">
                            <div>VICE PRESIDENT MARKETING</div>
                            <div>CO-FOUNDER</div>
                            <div class="linkedin">
                                <a
                                    href="https://www.linkedin.com/in/oanamonicavrabie/"
                                    rel="noreferrer"
                                    aria-label="oana-vrabie"
                                    target="_blank"
                                >
                                    <div class="icon-linkedin"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="member-card animation-our-team">
                        <div class="rounded-image">
                            <img
                                alt=""
                                src="../../../assets/IMG/ourteam/Our-team-Hans-Noser.webp"
                            />
                        </div>
                        <div class="member-name">
                            <div>hans noser</div>
                            <div class="delimiter-pink-bar"></div>
                        </div>
                        <div class="occupation noser">
                            <div>CHAIRMAN OF THE BOARD</div>
                            <div>CO-FOUNDER</div>
                            <div class="linkedin">
                                <a
                                    href="https://www.linkedin.com/in/hansnoser/"
                                    rel="noreferrer"
                                    aria-label="hansnoser"
                                    target="_blank"
                                >
                                    <div class="icon-linkedin"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--<div class="member-card animation-our-team">
                        <div class="rounded-image">
                            <img
                                alt=""
                                src="../../../assets/IMG/ourteam/Jay Siddharth.webp"
                            />
                        </div>
                        <div class="member-name">
                            <div>Jay Siddharth</div>
                            <div class="delimiter-pink-bar"></div>
                        </div>
                        <div class="occupation">
                            <div>Chief strategy officer</div>
                            &lt;!&ndash;He's not co-founder but if this is deleted it doesn't look right ;)&ndash;&gt;
                            <div id="hidden">CO-FOUNDER</div>
                            <div class="linkedin">
                                <a
                                    href="https://www.linkedin.com/in/jaysiddharth/"
                                    rel="noreferrer"
                                    aria-label="jay-siddharth"
                                    target="_blank"
                                >
                                    <div class="icon-linkedin"></div>
                                </a>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
            <div class="team-members-white-layer second-layer">
                <div class="team-members">
                    <div class="member-card animation-our-team">
                        <div class="rounded-image">
                            <img
                                alt=""
                                src="../../../assets/IMG/about/carlos.jpg"
                                style="object-fit: cover; object-position: center;"
                            />
                        </div>
                        <div class="member-name">
                            <div>Dr. Carlos Moreira</div>
                            <div class="delimiter-pink-bar"></div>
                        </div>
                        <div class="occupation">
                            <div>Laboratory</div>
                            <div>Project Manager</div>
                            <div class="linkedin">
                                <a
                                    href="https://www.linkedin.com/in/carlos-goncalves-moreira/"
                                    aria-label="carlos-moreira"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <div class="icon-linkedin"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="member-card animation-our-team">
                        <div class="rounded-image">
                            <img
                                alt=""
                                src="../../../assets/IMG/about/Group%207%20Copy%207.webp"
                            />
                        </div>
                        <div class="member-name">
                            <div>Gabriel Ciosu</div>
                            <div class="delimiter-pink-bar"></div>
                        </div>
                        <div class="occupation">
                            <div>Head of</div>
                            <div>architectural design</div>
                            <div class="linkedin">
                                <a
                                    href="https://www.linkedin.com/in/gabrielciosu/"
                                    rel="noreferrer"
                                    aria-label="gabriel-ciosu"
                                    target="_blank"
                                >
                                    <div class="icon-linkedin"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="testimonials">
            <div class="title">
                <div>Testimonials</div>
                <div class="delimiter"></div>
            </div>
            <div class="testimonial-card animation">
                <div class="testimonial-header">
                    <div class="testimonial-image">
                        <img
                            alt=""
                            src="../../../assets/IMG/testimonials/vonleoprecting_atypon.webp"
                        />
                    </div>
                    <div class="person-info">
                        <div class="name">Dr. Achim von Leoprechting</div>
                        <div class="occupation">
                            CEO of Tecan Group
                        </div>
                        <div class="delimiter"></div>
                    </div>
                    <div class="icon-quote"></div>
                </div>
                <div class="testimonial-text">
                    “Innovation needs collaboration. Collaboration needs interaction and exchange,
                    and I think the House of Lab Science provides all these elements in a very
                    elegant
                    and modern environment, offering big companies but also smaller startups the
                    opportunity
                    to connect, to challenge and support each other, and finally to learn from each
                    other
                    in terms of bringing forward innovations. This is the spirit in which Tecan
                    operates,
                    and that is why we are very enthusiastic about the House of Lab Science.”
                </div>
            </div>
            <div class="testimonial-card flipped animation">
                <div class="testimonial-header">
                    <div class="icon-quote-flipped"></div>
                    <div class="person-info">
                        <div class="name">Pius Fink</div>
                        <div class="occupation">
                            Lab automation entrepreneur
                        </div>
                        <div class="delimiter"></div>
                    </div>
                    <div class="testimonial-image">
                        <img
                            alt=""
                            src="../../../assets/IMG/testimonials/Pius-Fink.webp"
                        />
                    </div>
                </div>
                <div class="testimonial-text">
                    “Life sciences shape all our lives as they drive the world
                    towards health​, equality and sustainability. The House of
                    Lab Science enables entrepreneurial scientists and companies
                    to boost their projects, careers and businesses towards
                    success.”
                </div>
            </div>
            <div class="testimonial-card animation">
                <div class="testimonial-header">
                    <div class="testimonial-image">
                        <img
                            alt=""
                            src="../../../assets/IMG/testimonials/Michael-Collasius.webp"
                        />
                    </div>
                    <div class="person-info">
                        <div class="name">Dr. Michael Collasius</div>
                        <div class="occupation">
                            Co-Founder and CEO at HSE AG
                        </div>
                        <div class="delimiter"></div>
                    </div>
                    <div class="icon-quote"></div>
                </div>
                <div class="testimonial-text">
                    “As a global market leader for laboratory automation, HSE AG
                    is delighted to be under the same roof as the House of Lab
                    Science. We look forward to becoming part of a vibrant
                    community next to innovative startups as well as to boosting
                    career paths within HSE by making the offering of the Swiss
                    Center for Applied Economics of Lab Science accesible to my
                    team.”
                </div>
            </div>
        </div>
    </div>
</app-page-layout>
