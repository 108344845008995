<app-page-layout>
    <div pageContent class="community-container">
        <div class="testimonials-container">
            <div class="testimonials">
                <div
                    id="community-carousel"
                    class="carousel slide full-width-carousel"
                    data-bs-ride="carousel"
                >
                    <div class="carousel-indicators">
                        <button
                            type="button"
                            data-bs-target="#aboutCarousel"
                            *ngFor="
                                let testimonial of testimonialsArray;
                                let i = index
                            "
                            [class.active]="i === activeSlideIndex"
                        ></button>
                    </div>
                    <div class="carousel-inner">
                        <div
                            class="carousel-item tumbnail"
                            (click)="showCardAsCurrent(prevIndex)"
                        >
                            <div class="testimonial-header">
                                <div class="d-flex w-100">
                                    <div class="image">
                                        <img
                                            [src]="
                                                testimonialsArray[prevIndex].url
                                            "
                                        />
                                    </div>
                                    <div>
                                        <div class="name">
                                            {{
                                            testimonialsArray[prevIndex]
                                                .name
                                            }}
                                        </div>
                                        <div class="occupation">
                                            {{
                                            testimonialsArray[prevIndex]
                                                .occupation
                                            }}
                                        </div>
                                        <div class="delimiter"></div>
                                    </div>
                                    <div class="icon-quote ml-auto"></div>
                                </div>
                            </div>
                            <div class="testimonial-message">
                                {{ testimonialsArray[prevIndex].short_message || testimonialsArray[prevIndex].message }}
                            </div>
                        </div>
                        <div
                            class="carousel-item"
                            *ngFor="
                                let testimonial of testimonialsArray;
                                let i = index
                            "
                            data-bs-interval="0"
                            [class.active]="i === activeSlideIndex"
                        >
                            <div class="testimonial-header">
                                <div class="d-flex w-100">
                                    <div class="image">
                                        <img [src]="testimonial.url"/>
                                    </div>
                                    <div>
                                        <div class="name">
                                            {{ testimonial.name }}
                                        </div>
                                        <div class="occupation">
                                            {{ testimonial.occupation }}
                                        </div>
                                        <div class="delimiter"></div>
                                    </div>
                                    <div class="icon-quote ml-auto"></div>
                                </div>
                            </div>
                            <div class="testimonial-message" [ngClass]="testimonial.class">
                                {{ testimonial.message }}
                            </div>
                        </div>
                        <div
                            class="carousel-item tumbnail"
                            (click)="showCardAsCurrent(nextIndex)"
                        >
                            <div class="testimonial-header">
                                <div class="d-flex w-100">
                                    <div class="image">
                                        <img
                                            [src]="
                                                testimonialsArray[nextIndex].url
                                            "
                                        />
                                    </div>
                                    <div>
                                        <div class="name">
                                            {{
                                            testimonialsArray[nextIndex]
                                                .name
                                            }}
                                        </div>
                                        <div class="occupation">
                                            {{
                                            testimonialsArray[nextIndex]
                                                .occupation
                                            }}
                                        </div>
                                        <div class="delimiter"></div>
                                    </div>
                                    <div class="icon-quote ml-auto"></div>
                                </div>
                            </div>
                            <div class="testimonial-message">
                                {{ testimonialsArray[nextIndex].short_message || testimonialsArray[nextIndex].message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="community-carousel-ss"
                    class="carousel slide one-card-carousel"
                    data-bs-ride="carousel"
                >
                    <div class="carousel-indicators">
                        <button
                            type="button"
                            data-bs-target="#aboutCarousel"
                            *ngFor="
                                let testimonial of testimonialsArray;
                                let i = index
                            "
                            [class.active]="i === activeSlideIndex"
                        ></button>
                    </div>
                    <div class="carousel-inner">
                        <div
                            class="carousel-item active"
                            *ngFor="
                                let testimonial of testimonialsArray;
                                let i = index
                            "
                            [class.active]="i === activeSlideIndex"
                        >
                            <div class="testimonial-header">
                                <div class="d-flex w-100">
                                    <div class="image">
                                        <img [src]="testimonial.url"/>
                                    </div>
                                    <div>
                                        <div class="name">
                                            {{ testimonial.name }}
                                        </div>
                                        <div class="occupation">
                                            {{ testimonial.occupation }}
                                        </div>
                                        <div class="delimiter"></div>
                                    </div>
                                    <div class="icon-quote ml-auto"></div>
                                </div>
                            </div>
                            <div class="testimonial-message" [ngClass]="testimonial.class">
                                {{ testimonial.message }}
                            </div>
                        </div>
                    </div>
                    <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#community-carousel-ss"
                        (click)="showCardAsCurrent(prevIndex)"
                        data-bs-slide="prev"
                    >
                        <span class="icon-prev" aria-hidden="true"></span>
                    </button>
                    <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#community-carousel-ss"
                        (click)="showCardAsCurrent(nextIndex)"
                        data-bs-slide="next"
                    >
                        <span class="icon-next" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="we-welcome">
            <div class="title">
                <div>We welcome</div>
                <div class="delimiter"></div>
            </div>

            <div class="cards-section">
                <div class="welcome-card animation margin-right-48px top-left">
                    <div class="icon-life-science-startups"></div>
                    <div class="card-title mb-0">lab science &</div>
                    <div class="card-title">life sciences startups</div>
                    <div class="card-subtitle">profile</div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>
                            In need of laboratory space, technology and
                            infrastructure
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>Maturity level > 2 years</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>Minimum rental period > 2 years</div>
                    </div>
                    <div class="delimiter"></div>
                    <div class="card-subtitle-bold">benefits</div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>
                            Affordable lab space with lower sqm prices than in
                            the city of ZH, where waiting lists are filled
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>Community services by House of Lab Science</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>
                            Ecosystem effect due to incumbents (e.g. HSE, Tecan)
                            and other startups in the neighborhood
                        </div>
                    </div>
                    <div class="join-button-wrapper">
                        <div (click)="toRegister()" class=" join-button">
                            <span>Join Now</span>
                        </div>
                    </div>
                </div>
                <div class="welcome-card animation height-900-below-800 top-right">
                    <div class="icon-mature-life-science"></div>
                    <div class="card-title mb-0">mature lab science &</div>
                    <div class="card-title">life sciences companies</div>
                    <div class="card-subtitle">profile</div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>
                            Fast growing businesses in Life Sciences and IVD
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>Battle of talents</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>
                            Need to secure high level of IVD competence
                            throughout the supply chain
                        </div>
                    </div>
                    <div class="delimiter"></div>
                    <div class="card-subtitle-bold">benefits</div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>Time to market</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>Efficiency gains</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>High quality and compliant products</div>
                    </div>
                    <div class="join-button-wrapper">
                        <div (click)="toRegister()" class=" join-button">
                            <span>Join Now</span>
                        </div>
                    </div>
                </div>
                <div class="welcome-card animation margin-right-48px height-900 bottom-left">
                    <div class="icon-professionals"></div>
                    <div class="card-title">professionals</div>
                    <div class="card-subtitle">profile</div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>Employees in the Life Sciences Industry</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>Career change / re-orientation</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>
                            Education in IVD and Life Sciences Systems and
                            Project Management
                        </div>
                    </div>
                    <div class="delimiter"></div>
                    <div class="card-subtitle-bold">benefits</div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>Executive education and CAS certificates</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>
                            Training for the employees of companies that want to
                            develop innovative solutions in laboratory
                            technology
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>
                            Training for the employees of subcontracting
                            companies
                        </div>
                    </div>
                    <div class="join-button-wrapper">
                        <div (click)="toRegister()" class=" join-button">
                            <span>Join Now</span>
                        </div>
                    </div>
                </div>
                <div class="welcome-card animation height-900 bottom-right">
                    <div class="icon-property"></div>
                    <div class="card-title">property owners and managers</div>
                    <div class="card-subtitle">profile</div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>
                            Have industrial space that is not easily rented out
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>Have more than 3000sqm vacant space</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>
                            Willing to invest in polishing and repurposing their
                            buildings (lab infrastructure)
                        </div>
                    </div>
                    <div class="delimiter"></div>
                    <div class="card-subtitle-bold">benefits</div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>Valorization of vacant spaces / buildings</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>Steady and higher rental payments</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>Part of a flourishing ecosystem</div>
                    </div>
                    <div class="join-button-wrapper">
                        <div (click)="toRegister()" class=" join-button">
                            <span>Join Now</span>
                        </div>
                    </div>
                </div>
                <div class="welcome-card animation center-card height-1000 bottom">
                    <div class="icon-investor"></div>
                    <div class="card-title">investors</div>
                    <div class="card-subtitle">profile</div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>
                            From private investors and business angels to family
                            offices and VCs
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>Active in Switzerland</div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle"></div>
                        <div>
                            Investment focus on life sciences and startups with
                            financing needs from seed round to Series A
                        </div>
                    </div>
                    <div class="delimiter"></div>
                    <div class="card-subtitle-bold">benefits</div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>
                            Unique investment opportunities for an
                            innovation/startups-driven and thriving industry
                            which is one of the main pillars of the Swiss
                            economy
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>
                            Match-making days as dedicated events with the top
                            life sciences startups in Switzerland
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="icon-checked-circle-fill"></div>
                        <div>
                            Exclusive networking events and access to the
                            vibrating community at the House of Lab Science
                            across all sites
                        </div>
                    </div>
                    <div class="join-button-wrapper">
                        <div (click)="toRegister()" class=" join-button">
                            <span>Join Now</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-page-layout>
