import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HlsServicesService } from 'src/app/services/hls-services.service';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { ActivatedRoute } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-our-services',
    templateUrl: './our-services.component.html',
    styleUrls: ['./our-services.component.scss'],
})
export class OurServicesComponent implements OnInit, AfterViewInit {
    countDownDate = new Date('Apr 04, 2022 00:00:00').getTime();
    now = new Date().getTime();
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    services: any;
    availabilityList = ['ON_SITE', 'ONLINE'];
    servicesCategory = [
        'LAB_MANAGEMENT',
        'LAB_SUP_MILIAN_SA',
        'INNOVATION_MANAGEMENT',
        'FACILITY_MANAGEMENT',
        'ICT_MANAGEMENT',
        'OTHER',
    ];

    constructor(
        private dialog: MatDialog,
        private hlsServices: HlsServicesService,
        private route: ActivatedRoute,
        private titleService: Title
    ) {
        hlsServices.getServices().subscribe((data) => {
            this.services = data;
        });
    }

    ngOnInit(): void {
        this.countDownFunction();
        this.titleService.setTitle('House of Lab Science services | HLS');
    }

    ngAfterViewInit(): void {
        this.route.queryParams.subscribe((params) => {
            const key = 'subscribe';
            const subscribe = params[key];
            if (subscribe) {
                this.subscribeToNewsletter();
            }
        });
    }

    countDownFunction(): void {
        this.now = new Date().getTime();
        if (this.countDownDate > this.now) {
            const timeleft = this.countDownDate - this.now;

            this.days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            this.hours = Math.floor(
                (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            this.minutes = Math.floor(
                (timeleft % (1000 * 60 * 60)) / (1000 * 60)
            );
            this.seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

            setTimeout(() => {
                this.countDownFunction();
            }, 1000);
        } else {
            this.days = 0;
            this.hours = 0;
            this.minutes = 0;
            this.seconds = 0;
        }
    }

    subscribeToNewsletter(): void {
        this.dialog.open(SubscribeComponent);
    }

    downloadServicesCatalog(): void {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute(
            'href',
            'https://hls-resources.s3.eu-central-1.amazonaws.com/services.pdf'
        );
        link.setAttribute('download', `services_catalog.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
