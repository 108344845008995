import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-community',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss'],
})
export class CommunityComponent implements OnInit {
    @ViewChild('slide') slide: any;

    activeSlideIndex = 1;
    nextIndex = 2;
    prevIndex = 0;

    testimonialsArray: Array<{
        name: string;
        url: string;
        occupation: string;
        message: string;
        short_message?: string;
        class?: string;
    }> = [
        {
            name: 'Dr. Michael Collasius',
            url: '../../../assets/IMG/testimonials/Michael-Collasius.webp',
            occupation: 'Co-Founder and CEO at HSE AG',
            message:
                '“As a global market leader for laboratory automation, HSE AG is delighted to be under the same roof as the House of Lab Science. We look forward to becoming part of a vibrant community next to innovative startups as well as to boosting career paths within HSE by making the offering of the Swiss Center for Applied Economics of Lab Science accesible to my team.”',
        },
        {
            name: 'Dr. Achim von Leoprechting',
            url: '../../../assets/IMG/testimonials/vonleoprecting_atypon.webp',
            occupation: 'CEO of Tecan Group',
            message:
                '“Innovation needs collaboration. Collaboration needs interaction and exchange, and I think the House of Lab Science provides all these elements in a very elegant and modern environment, offering big companies but also smaller startups the opportunity to connect, to challenge and support each other, and finally to learn from each other in terms of bringing forward innovations. This is the spirit in which Tecan operates, and that is why we are very enthusiastic about the House of Lab Science.”',
            short_message: '“Innovation needs collaboration. Collaboration needs interaction and exchange, and I think the House of Lab Science provides all these elements in a very elegant and modern environment, offering big companies but also smaller startups the opportunity to connect...”',
            class: 'little-message'
        },
        {
            name: 'Pius Fink',
            url: '../../../assets/IMG/testimonials/Pius-Fink.webp',
            occupation: 'Lab automation entrepreneur',
            message:
                '“Life sciences shape all our lives as they drive the world towards health​, equality and sustainability. The House of Lab Science enables entrepreneurial scientists and companies to boost their projects, careers and businesses towards success.”',
        },
        {
            name: 'Hans Noser',
            url: '../../../assets/IMG/ourteam/Hans.webp',
            occupation: 'Founder of House of Lab Science',
            message:
                '“Being part of the House of Lab Science community means that you will be in the center of a constant flow of ideas, collaboration and business opportunities across generations and disciplines, giving your career, project or business the best chance of success.”',
        },
    ];

    private observer: IntersectionObserver;

    constructor(public element: ElementRef, @Inject(PLATFORM_ID) private platformId: any, private titleService: Title) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.subscribeElementView();
        }
        this.titleService.setTitle('Join House of Lab science community | HLS');
    }
    private handleIntersect(entries): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
            }
        });
    }

    private subscribeElementView(): void {
        this.destroyObserver();
        this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) =>
        {
            this.handleIntersect(entries);
        });
        const animationElements = document.querySelectorAll('.animation');
        animationElements.forEach((el) => this.observer.observe(el));
    }
    private destroyObserver(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    showCardAsCurrent(index: number): void {
        this.activeSlideIndex = index;
        this.prevIndex = this.activeSlideIndex - 1;
        this.nextIndex = this.activeSlideIndex + 1;

        if (this.activeSlideIndex === 0) {
            this.prevIndex = this.testimonialsArray.length - 1;
            this.nextIndex = this.activeSlideIndex + 1;
        }

        if (this.activeSlideIndex === this.testimonialsArray.length - 1) {
            this.prevIndex = this.activeSlideIndex - 1;
            this.nextIndex = 0;
        }
    }
    toRegister(): void{
        window.location.href = 'user/register';
    }
}
