<div pageContent class="community-container">
    <div class="testimonials-container">
        <div class="testimonials">
            <div class="title">
                Services in the spotlight
                <div class="delimiter"></div>
            </div>
            <div
                id="community-carousel"
                class="carousel slide full-width-carousel"
                data-bs-ride="carousel"
                *ngIf="services?.length"
            >
                <div class="carousel-indicators">
                    <button
                        type="button"
                        data-bs-target="#aboutCarousel"
                        *ngFor="let service of services; let i = index"
                        [class.active]="i === activeSlideIndex"
                    ></button>
                </div>
                <div class="carousel-inner">
                    <div
                        class="carousel-item tumbnail"
                        (click)="showCardAsCurrent(prevIndex)"
                    >
                        <div class="testimonial-header">
                            <a
                                *ngIf="services[prevIndex]?.website"
                                class="logo"
                                [href]="services[prevIndex]?.website"
                            >
                                <img [src]="services[prevIndex].logo"/>
                            </a>
                            <a
                                *ngIf="!services[prevIndex]?.website"
                                class="logo"
                            >
                                <img [src]="services[prevIndex].logo"/>
                            </a>
                            <div class="links">
                                <div *ngFor="let link of socialMedia">
                                    <a
                                        *ngIf="services[prevIndex][link]"
                                        [href]="services[prevIndex][link]"
                                        target="_blank"
                                    ><img
                                        [src]="
                                                '../../../assets/IMG/our-service/spotlight/' +
                                                link +
                                                '.svg'
                                            "
                                    /></a>
                                </div>
                            </div>
                        </div>
                        <div class="occupation">
                            {{ services[prevIndex]?.name }}
                        </div>
                        <div class="delimiter"></div>
                        <div
                            class="description"
                            [innerHTML]="services[prevIndex]?.description"
                        ></div>
                    </div>
                    <div
                        class="carousel-item"
                        *ngFor="let service of services; let i = index"
                        data-bs-interval="0"
                        [class.active]="i === activeSlideIndex"
                    >
                        <div class="testimonial-header">
                            <a
                                *ngIf="service?.website"
                                class="logo"
                                [href]="service?.website"
                            >
                                <img [src]="service.logo"/>
                            </a>
                            <a *ngIf="!service?.website" class="logo">
                                <img [src]="service.logo"/>
                            </a>
                            <div class="links">
                                <div *ngFor="let link of socialMedia">
                                    <a
                                        *ngIf="service[link]"
                                        [href]="service[link]"
                                        target="_blank"
                                    ><img
                                        [src]="
                                                '../../../assets/IMG/our-service/spotlight/' +
                                                link +
                                                '.svg'
                                            "
                                    /></a>
                                </div>
                            </div>
                        </div>

                        <div class="occupation">
                            {{ service?.name }}
                        </div>
                        <div>
                            <div class="delimiter"></div>
                        </div>
                        <div
                            class="description"
                            [innerHTML]="service?.description"
                        ></div>
                    </div>
                    <div
                        class="carousel-item tumbnail"
                        (click)="showCardAsCurrent(nextIndex)"
                    >
                        <div class="testimonial-header">
                            <a
                                *ngIf="services[nextIndex]?.website"
                                class="logo"
                                [href]="services[nextIndex]?.website"
                            >
                                <img [src]="services[nextIndex].logo"/>
                            </a>
                            <a
                                *ngIf="!services[nextIndex]?.website"
                                class="logo"
                            >
                                <img [src]="services[nextIndex].logo"/>
                            </a>
                            <div class="links">
                                <div *ngFor="let link of socialMedia">
                                    <a
                                        *ngIf="services[nextIndex][link]"
                                        [href]="services[nextIndex][link]"
                                        target="_blank"
                                    ><img
                                        [src]="
                                                '../../../assets/IMG/our-service/spotlight/' +
                                                link +
                                                '.svg'
                                            "
                                    /></a>
                                </div>
                            </div>
                        </div>

                        <div class="occupation">
                            {{ services[nextIndex]?.name }}
                        </div>
                        <div>
                            <div class="delimiter"></div>
                        </div>
                        <div
                            class="description"
                            [innerHTML]="services[nextIndex]?.description"
                        ></div>
                    </div>
                </div>
            </div>
            <div
                id="community-carousel-ss"
                class="carousel slide one-card-carousel"
                data-bs-ride="carousel"
            >
                <div class="card-wrap">
                    <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#community-carousel-ss"
                        (click)="showCardAsCurrent(prevIndex)"
                        data-bs-slide="prev"
                    >
                        <span class="icon-prev" aria-hidden="true"></span>
                    </button>
                    <div class="carousel-inner">
                        <div
                            class="carousel-item active"
                            *ngFor="let service of services; let i = index"
                            [class.active]="i === activeSlideIndex"
                        >
                            <div class="testimonial-header">
                                <a
                                    *ngIf="service?.website"
                                    class="logo"
                                    [href]="service?.website"
                                >
                                    <img [src]="service.logo"/>
                                </a>
                                <a *ngIf="!service?.website" class="logo">
                                    <img [src]="service.logo"/>
                                </a>
                                <div class="links">
                                    <div *ngFor="let link of socialMedia">
                                        <a
                                            *ngIf="service[link]"
                                            [href]="service[link]"
                                            target="_blank"
                                        ><img
                                            [src]="
                                                    '../../../assets/IMG/our-service/spotlight/' +
                                                    link +
                                                    '.svg'
                                                "
                                        /></a>
                                    </div>
                                </div>
                            </div>
                            <div class="occupation">
                                {{ service?.name }}
                            </div>
                            <div class="delimiter"></div>
                            <div
                                class="description"
                                [innerHTML]="service?.description"
                            ></div>
                        </div>
                    </div>
                    <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#community-carousel-ss"
                        (click)="showCardAsCurrent(nextIndex)"
                        data-bs-slide="next"
                    >
                        <span class="icon-next" aria-hidden="true"></span>
                    </button>
                </div>
                <div class="carousel-indicators">
                    <button
                        type="button"
                        data-bs-target="#aboutCarousel"
                        *ngFor="let service of services; let i = index"
                        [class.active]="i === activeSlideIndex"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</div>
