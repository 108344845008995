<div class="location-footer">
    <div class="join" (click)="toRegister()">JOIN US</div>
    <div class="message">and be part of a highly diverse, innovation-friendly, yet business-driven lab tech cluster!</div>
</div>
<div class="logo-footer">
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/logo_lszbn_web-white.webp"
            width="191px"
            height="62px"
        />
    </div>
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/Zurichsee-logo.webp"
            width="130px"
            height="48px"
        />
    </div>
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/Bio-technopark-logo.webp"
            width="323"
            height="48px"
        />
    </div>
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/Varioserv-logo.webp"
            width="174px"
            height="64px"
        />
    </div>
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/Tool-point-logo.webp"
            width="113px"
            height="64px"
        />
    </div>
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/Logo-HTCS_farbig.webp"
            width="147px"
            height="64px"
        />
    </div>
    <!-- <div class="logo-item">
        <img alt="" src="../../../assets/logos/logo_kal-fh_claim_4c_d_transp.webp" width="160px" height="64px">
    </div> -->
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/Zuerioberland_Standortfoerderung_Logo_100k.webp"
            width="56px"
            height="64px"
        />
    </div>
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/SBA-Logo-Member-neg.webp"
            width="194px"
            height="64px"
        />
    </div>
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/mettler2-invest-ag-vector-logo.svg"
            width="194px"
            height="64px"
        />
    </div>
    <div class="logo-item">
        <img
            alt=""
            src="../../../assets/logos/Kalaidos%20logo%20footer.webp"
            width="194px"
            height="64px"
        />
    </div>
</div>
<div class="background-footer">
    <div class="gradient-footer">
        <div class="info-container">
            <div class="half-size">
                <div class="logo-column">
                    <div class="logo" routerLink=""></div>
                    <div class="text">
                        Where lab science innovation and business are at home
                    </div>
                </div>
                <div class="contact-column">
                    <div>
                        <div class="title-text">Contact</div>
                        <div class="location">
                            <a
                                href="https://www.google.ro/maps/place/Garstligweg+8,+8634+Hombrechtikon,+Switzerland/@47.2469003,8.7796704,18z/data=!4m5!3m4!1s0x479ab0c6f10ce99d:0xf143e128a0463563!8m2!3d47.2466628!4d8.7800945"
                                target="_blank"
                                rel="noreferrer"
                                aria-label="place-map"
                            >
                                <div class="icon-place"></div>
                                <span>Garstligweg 8, 8634 Hombrechtikon</span>
                            </a>
                        </div>
                        <div class="email">
                            <a
                                href="mailto:info@houseoflabscience.world"
                                rel="noreferrer"
                                aria-label="email-to"
                                target="_blank"
                            >
                                <div class="icon-email"></div>
                                <span>info@houseoflabscience.world</span>
                            </a>
                        </div>
                        <div class="social-links">
                            <a
                                href="https://www.linkedin.com/company/house-of-lab-science/about/"
                                aria-label="hls-linkedin"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <div class="icon-linkedin"></div>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCruFoW2_z8FIP3x2jyYH-5A"
                                rel="noreferrer"
                                aria-label="hls-youtube"
                                target="_blank"
                            >
                                <div class="icon-youtube"></div>
                            </a>
                            <a
                                href="https://twitter.com/HouseLabScience"
                                rel="noreferrer"
                                aria-label="hls-twitter"
                                target="_blank"
                            >
                                <div class="icon-twitter"></div>
                            </a>
                        </div>
                    </div>
                    <div class="redirect-pages-column">
                        <div class="page" routerLink="/">Home</div>
                        <div class="page" routerLink="/locations">Locations</div>
                        <div class="page" routerLink="/community">
                            Community
                        </div>
                        <div class="page" routerLink="/our-services">
                            Our services
                        </div>
                        <div class="page" routerLink="/lab-science-academy">Lab Science Academy</div>
                        <div class="page" routerLink="/media">Media</div>
                    </div>
                </div>
            </div>
            <div class="half-size">
                <div class="redirect-pages-column">
                    <div class="page" routerLink="/">Home</div>
                    <div class="page" routerLink="/locations">Locations</div>
                    <div class="page" routerLink="/community">Community</div>
                    <div class="page" routerLink="/our-services">
                        Our services
                    </div>
                    <div class="page" routerLink="/lab-science-academy">Lab Science Academy</div>
                    <div class="page" routerLink="/media">Media</div>
                </div>
                <div class="subscribe-column">
                    <div>
                        <div class="title-text">
                            Subscribe to our newsletter
                        </div>
                        <div class="info-text">
                            Always find out the latest about House of Lab
                            Science before anyone else.
                        </div>
                    </div>
                    <button class="subscribe-button" (click)="openSubscribe()">
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
        <div class="copyright-and-terms">
            <div class="copyright">
                © Copyright 2022 <span>House of Lab Science AG</span>. All
                rights reserved.
            </div>
            <div class="terms">
                <a routerLink="/terms-and-conditions">Terms & Conditions</a>
                <div>&nbsp;/&nbsp;</div>
                <a routerLink="/privacy-policy">Privacy Policy</a>
            </div>
        </div>
    </div>
</div>
