import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './app.component.html',
    styleUrls: [
        '../assets/less/hls-calendar.less'
    ]
})
export class AppComponent implements OnInit {
    keywords: string[] = ['labs',
        'labspace',
        'laboratory space',
        'biolabs',
        'biosafety certified labs',
        'wet lab space',
        'lab-as-a-service',
        'labs for rent',
        'wet lab for rent',
        'ready-to-move-in labs',
        'ready-to-go-labs',
        'Nasslabor',
        'Labor'];

    constructor(
        private titleService: Title,
        private metaTagService: Meta
    ) {
    }

    ngOnInit(): void {
        this.titleService.setTitle('Life sciences clusters & lab space in Switzerland | HLS ');
        this.metaTagService.addTags([
            {
                name: 'keywords',
                content: this.keywords.join(', ')
            },
            {name: 'robots', content: 'index, follow'},
        ]);
    }
}
