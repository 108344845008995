import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../modules/user/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class IsUserSetGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) {}

    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (!this.userService.getUser()) {
            this.router.navigate(['/dashboard']);
            return false;
        }

        return true;
    }
}
