import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/services/user.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    emailSent = false;
    username = new FormControl('', [Validators.required]);

    constructor(private userService: UserService) {}

    ngOnInit(): void {}

    send(): void {
        this.userService
            .forgotPassword(this.username.value.toLowerCase())
            .subscribe(
                () => {
                    this.emailSent = true;
                },
                (error) => {
                    if (error.status === 404) {
                        this.username.setErrors({ notExists: true });
                    }
                }
            );
    }
}
