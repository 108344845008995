<div class="reset-password-container">
    <div class="logo"></div>
    <div class="cards-container card-1" *ngIf="!passwordSet">
        <mat-card class="card-type">
            <div>
                <form [formGroup]="form" class="form">
                    <div class="text-big">Reset password</div>
                    <div class="text-small"></div>
                    <app-form-input
                        *ngIf="!token"
                        [formControl]="form.controls.currentPassword"
                        [isPasswordInput]="true"
                        [isRequired]="true"
                        placeholder="Current password"
                        [inputType]="'password'"
                    >
                    </app-form-input>
                    <div
                        class="password-hint"
                        *ngIf="
                            !token &&
                            form.controls.currentPassword.dirty &&
                            form.controls.currentPassword.errors?.pattern &&
                            !form.controls.currentPassword.errors?.required
                        "
                    >
                        <p>Your password should be at least 8 characters.</p>
                        <p>
                            Mix upper case, lower case, punctuation and numbers.
                        </p>
                    </div>
                    <app-form-input
                        [formControl]="form.controls.password"
                        [isPasswordInput]="true"
                        [isRequired]="true"
                        placeholder="New password"
                        [inputType]="'password'"
                    >
                    </app-form-input>
                    <div
                        class="password-hint"
                        *ngIf="
                            form.controls.password.dirty &&
                            form.controls.password.errors?.pattern &&
                            !form.controls.password.errors?.required
                        "
                    >
                        <p>Your password should be at least 8 characters.</p>
                        <p>
                            Mix upper case, lower case, punctuation and numbers.
                        </p>
                    </div>
                    <app-form-input
                        [formControl]="form.controls.confirmPassword"
                        [isRequired]="true"
                        [isPasswordInput]="true"
                        placeholder="Repeat password"
                        [inputType]="'password'"
                    >
                    </app-form-input>
                    <div
                        class="match-password"
                        *ngIf="
                            form.controls.confirmPassword.dirty &&
                            form.controls.confirmPassword.errors?.mustMatch
                        "
                    >
                        Passwords do not match.
                    </div>
                    <div
                        class="invalid-token"
                        *ngIf="token && form.errors?.invalidToken"
                    >
                        Invalid token, please try again.
                    </div>
                    <div
                        class="invalid-token"
                        *ngIf="!token && form.errors?.invalidToken"
                    >
                        Current password is wrong.
                    </div>
                    <button
                        class="continue-button"
                        [disabled]="!form.valid"
                        (click)="form.valid && send()"
                    >
                        Send
                    </button>
                    <a
                        class="back-button"
                        routerLink="/dashboard"
                        *ngIf="!token"
                        >&#60;&#60; Back</a
                    >
                </form>
            </div>
        </mat-card>
    </div>
    <div class="cards-container card-2" *ngIf="passwordSet">
        <mat-card class="card-type">
            <div>
                <div class="text-big">Your password is set</div>
                <div class="text-small">
                    Your account was successfully updated with your new
                    password.
                </div>
                <a routerLink="/dashboard"
                    ><button class="continue-button">Continue</button></a
                >
            </div>
        </mat-card>
    </div>

    <div class="footer-image">
        <div class="terms-privacy">
            <a class="terms" routerLink="/terms-and-conditions" target="_blank"
                >Terms & Conditions</a
            >
            <a class="privacy" routerLink="/privacy-policy" target="_blank"
                >Privacy policy</a
            >
        </div>
    </div>
</div>
