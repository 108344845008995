import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HlsServicesService {
    constructor(private http: HttpClient) {}

    getServices(forDashboard = false): Observable<any> {
        return this.http.get(
            environment.apiUrl + '/services?main=' + forDashboard
        );
    }
}
