import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { CommunityComponent } from './components/community/community.component';
import { ContactComponent } from './components/contact/contact.component';
import { ForgotPasswordComponent } from './modules/user/components/forgot-password/forgot-password.component';
import { MediaComponent } from './components/media/media.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ResetPasswordComponent } from './modules/user/components/reset-password/reset-password.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { AuthGuard } from './guards/auth.guard';
import { IsUserSetGuard } from './guards/is-user-set.guard';
import { UserResolver } from './resolvers/user.resolver';
import { MediaArticleComponent } from './components/media-article/media-article.component';
import {LocationComponent} from './components/location/location.component';
import {LabScienceAcademyComponent} from "./components/lab-science-academy/lab-science-academy.component";

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        children: [
            {
                path: '',
                component: AboutComponent,
            },
            {
                path: 'locations',
                component: LocationComponent,
            },
            {
                path: 'community',
                component: CommunityComponent,
            },
            {
                path: 'our-services',
                component: OurServicesComponent,
            },
            {
                path: 'media',
                component: MediaComponent,
            },
            {
                path: 'media/:title',
                component: MediaComponent,
            },
            {
                path: 'lab-science-academy',
                component: LabScienceAcademyComponent,
            },
            {
                path: 'media-article/:title',
                component: MediaArticleComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            },
            {
                path: 'terms-and-conditions',
                component: TermsAndConditionsComponent,
            },
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
            {
                path: 'register',
                redirectTo: 'dashboard/home'
            },
            {
                path: 'user',
                loadChildren: () =>
                    import('./modules/user/user.module').then(
                        (m) => m.UserModule
                    ),
            },
            {
                path: 'dashboard',
                resolve: {
                    user: UserResolver,
                },
                loadChildren: () =>
                    import('./modules/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'payments',
                loadChildren: () =>
                    import('./modules/payments/payments.module').then(
                        (m) => m.PaymentsModule
                    ),
                canActivate: [IsUserSetGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
