import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscribeModel } from '../models/subscribe.model';

@Injectable({
    providedIn: 'root',
})
export class SubscribeService {
    constructor(private httpClient: HttpClient) {}

    subscribeToNotifications(subscribeModel: SubscribeModel): Observable<any> {
        const apiURL =
            'https://world.us20.list-manage.com/subscribe/post-json?u=693edfbe9e39a7f5f72a3cadc&amp;id=c52baa9b06&';

        const params = new HttpParams()
            .set('EMAIL', subscribeModel.email)
            .set('FNAME', subscribeModel.firstName)
            .set('LNAME', subscribeModel.lastName)
            .set('ABOUT_YOU', subscribeModel.tellUsAboutYourself)
            .set('ROLE', subscribeModel.jobTitle)
            .set('CNAME', subscribeModel.companyName);

        const mailChimpUrl = apiURL + params.toString();

        return this.httpClient.jsonp(mailChimpUrl, 'c');
    }
}
