import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { AppointmentService } from 'src/app/services/appointment.service';
import { ToastrService } from 'ngx-toastr';
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    contactForm: FormGroup;
    formSubmitted: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private appointmentService: AppointmentService,
        private toastrService: ToastrService,
        private titleService: Title
    ) {}

    ngOnInit(): void {
        this.contactForm = this.formBuilder.group({
            fullName: new FormControl('', [Validators.required]),
            email: ['', [Validators.required, Validators.email]],
            subject: ['', Validators.required],
            message: ['', Validators.required],
            recaptcha: ['', Validators.required],
        });
        this.titleService.setTitle('Get in touch with us | HLS');

    }

    send(): void {
        this.formSubmitted = true;

        if (!this.contactForm.valid) {
            return;
        }

        this.appointmentService
            .sendAppointment(this.contactForm.value)
            .subscribe(
                (res) => {
                    this.formSubmitted = false;
                    this.contactForm.reset();
                    this.contactForm.markAsUntouched();
                    this.contactForm.updateValueAndValidity();
                    this.toastrService.success(
                        'Appointment booked successfully.'
                    );
                },
                (err) => {
                    this.formSubmitted = false;
                    this.toastrService.error(
                        'There was an issue booking the appointment. Try again later.'
                    );
                }
            );
    }
}
