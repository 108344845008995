import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppointmentService {
    constructor(private http: HttpClient) {}

    sendAppointment(body: any): Observable<any> {
        return this.http.post(
            'https://u5stppqsab.execute-api.eu-central-1.amazonaws.com/email/contact',
            body
        );
    }
}
