import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from 'src/app/components/form-input/form-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { PageLayoutCardComponent } from 'src/app/modules/user/components/register/page-layout-card/page-layout-card.component';
import { RouterModule } from '@angular/router';
import { HlsUserNamePipe } from './pipes/hls-user-name.pipe';
import { HlsUserTypeColor } from './pipes/hls-user-type-color.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { Safe } from './pipes/safe.pipe';
import { HlsServiceCategoryPipe } from './pipes/hls-service-category.pipe';

@NgModule({
    declarations: [
        FormInputComponent,
        PageLayoutCardComponent,
        HlsUserNamePipe,
        HlsUserTypeColor,
        TimeAgoPipe,
        Safe,
        HlsServiceCategoryPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        RouterModule,
        BsDatepickerModule.forRoot(),
    ],
    exports: [
        FormInputComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        PageLayoutCardComponent,
        RouterModule,
        HlsUserNamePipe,
        HlsUserTypeColor,
        TimeAgoPipe,
        Safe,
        BsDatepickerModule,
        HlsServiceCategoryPipe
    ],
})
export class SharedModule {}
