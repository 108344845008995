import {Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {Subject} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
    showImageCarousel: boolean;
    activeSlideIndex = 0;
    carouselImages: Array<string>;
    prevIndex: number;
    nextIndex: number;

    commonImages = [
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space1.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space2.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space3.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space4.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space5.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space6.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space7.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space8.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space9.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space10.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space11.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space12.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space13.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space14.webp',
        '../../../assets/IMG/about-tabs/common-spaces/HLS_Space15.webp',
    ];

    eventsImages = [
        '../../../assets/IMG/about-tabs/hls-lecture-hall/HLS_Hall1.webp',
        '../../../assets/IMG/about-tabs/hls-lecture-hall/HLS_Hall2.webp',
        '../../../assets/IMG/about-tabs/hls-lecture-hall/HLS_Hall3.webp',
        '../../../assets/IMG/about-tabs/hls-lecture-hall/HLS_Hall4.webp',
        '../../../assets/IMG/about-tabs/hls-lecture-hall/HLS_Hall5.webp',
    ];

    labSpacesImages = [
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office1.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office2.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office3.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office4.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office5.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office6.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office7.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office8.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office9.webp',
        '../../../assets/IMG/about-tabs/office-meeting-rooms/HLS_Office10.webp',
    ];

    outdoorImages = [
        '../../../assets/IMG/about-tabs/outside/HLS1.webp',
        '../../../assets/IMG/about-tabs/outside/HLS2.webp',
        '../../../assets/IMG/about-tabs/outside/HLS3.webp',
        '../../../assets/IMG/about-tabs/outside/HLS4.webp',
        '../../../assets/IMG/about-tabs/outside/HLS5.webp',
        '../../../assets/IMG/about-tabs/outside/HLS6.webp',
        '../../../assets/IMG/about-tabs/outside/HLS7.webp',
        '../../../assets/IMG/about-tabs/outside/HLS8.webp',
        '../../../assets/IMG/about-tabs/outside/HLS9.webp',
        '../../../assets/IMG/about-tabs/outside/HLS10.webp',
    ];

    videos = [
        '../../../assets/IMG/about-tabs/videos/video1.mp4',
        '../../../assets/IMG/about-tabs/videos/video3.mp4',
    ];

    commonImagesThumbnail = [
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space1.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space2.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space3.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space4.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space5.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space6.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space7.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space8.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space9.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space10.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space11.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space12.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space13.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space14.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/common-spaces/HLS_Space15.webp',
    ];

    eventsImagesThumbnail = [
        '../../../assets/IMG/Thumbnails/about-tabs/hls-lecture-hall/HLS_Hall1.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/hls-lecture-hall/HLS_Hall2.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/hls-lecture-hall/HLS_Hall3.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/hls-lecture-hall/HLS_Hall4.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/hls-lecture-hall/HLS_Hall5.webp',
    ];

    labSpacesImagesThumbnail = [
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office1.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office2.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office3.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office4.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office5.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office6.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office7.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office8.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office9.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/office-meeting-rooms/HLS_Office10.webp',
    ];

    outdoorImagesThumbnail = [
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS1.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS2.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS3.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS4.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS5.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS6.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS7.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS8.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS9.webp',
        '../../../assets/IMG/Thumbnails/about-tabs/outside/HLS10.webp',
    ];

    allImages = [
        ...this.eventsImages,
        ...this.labSpacesImages,
        ...this.commonImages,
        ...this.outdoorImages,
    ];
    private observer: IntersectionObserver;
    private observerOneTime: IntersectionObserver;


    constructor(public element: ElementRef, @Inject(PLATFORM_ID) private platformId: any, private titleService: Title) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.subscribeElementView();
        }
        this.titleService.setTitle('Life sciences cluster & lab space in Switzerland | HLS');
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent): void {
        const x = event.keyCode;
        if (x === 27) {
            this.showImageCarousel = false;
        }
    }

    onShowImageCarousel(index: number, imageList: Array<string>): void {
        this.showImageCarousel = true;
        this.activeSlideIndex = index;
        this.carouselImages = imageList;
        this.prevIndex = this.activeSlideIndex - 1;
        this.nextIndex = this.activeSlideIndex + 1;

        if (this.activeSlideIndex === 0) {
            this.prevIndex = this.carouselImages.length - 1;
            this.nextIndex = this.activeSlideIndex + 1;
        }

        if (this.activeSlideIndex === this.carouselImages.length - 1) {
            this.nextIndex = 0;
            this.prevIndex = this.activeSlideIndex - 1;
        }
    }

    prevSlide(): void {
        this.activeSlideIndex--;
        this.prevIndex = this.activeSlideIndex - 1;
        this.nextIndex = this.activeSlideIndex + 1;

        if (this.activeSlideIndex === 0) {
            this.prevIndex = this.carouselImages.length - 1;
            this.nextIndex = 1;
        }

        if (this.activeSlideIndex < 0) {
            this.activeSlideIndex = this.carouselImages.length - 1;
            this.prevIndex = this.activeSlideIndex - 1;
            this.nextIndex = 0;
        }
    }

    nextSlide(): void {
        this.activeSlideIndex++;
        this.nextIndex = this.activeSlideIndex + 1;
        this.prevIndex = this.activeSlideIndex - 1;

        if (this.activeSlideIndex === this.carouselImages.length - 1) {
            this.nextIndex = 0;
            this.prevIndex = this.carouselImages.length - 1;
        }

        if (this.activeSlideIndex > this.carouselImages.length - 1) {
            this.activeSlideIndex = 0;
            this.nextIndex = 1;
            this.prevIndex = this.carouselImages.length - 1;
        }
    }
    toRegister(): void{
        window.location.href = 'user/register';
    }
    private handleIntersect(entries): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
            }
            else{
                entry.target.classList.remove('active');
            }
        });
    }
    private handleOneTimeIntersect(entries): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
            }
        });
    }

    private subscribeElementView(): void {
        this.destroyObserver();
        this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) =>
            {
                this.handleIntersect(entries);
            });
        this.observerOneTime = new IntersectionObserver((entries: IntersectionObserverEntry[]) =>
            {
                this.handleOneTimeIntersect(entries);
            });
        const animationElements = document.querySelectorAll('.animation');
        const animationOneTimeElements = document.querySelectorAll('.animation-our-team');
        animationElements.forEach((el) => this.observer.observe(el));
        animationOneTimeElements.forEach((el) => this.observerOneTime.observe(el));
    }
    private destroyObserver(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

}
