<app-page-layout>
    <div pageContent class="lab-science-academy">
        <div class="title-section">
            <div class="animation">Educate. Innovate. Lead:</div>
            <div class="animation">Empowering the Lab Scientists of Tomorrow</div>
        </div>
        <div class="our-vision">
            <div class="title animation">our vision</div>
            <div class="text animation">The Lab Science Academy is established to unlock the Labs of the Future and to
                empower the Lab Scientists of Tomorrow.
            </div>
            <div class="text animation">Through the <span (click)="scrollTo('educational-offering')" class="underline">Academy’s educational offering</span>,
                we
                empower and prepare employees in the life sciences and laboratory technology for their future <b>roles
                    and
                    jobs.</b>
            </div>
            <div class="text animation">Our <span (click)="scrollTo('translational-research')"
                                                  class="underline">Translational research</span> activities utilize a
                strong network of academic partners in AI, digitization, and automation to develop
                future labs with minimal human intervention, relying on data-driven plant control and optimization.
            </div>
            <div class="text centered animation">For more detailed information about the Lab Science Academy please
                click
            </div>
            <a href="http://www.labscience.academy/"
               target="_blank"
               class="text here-button centered text-uppercase font-weight-bold animation">
                <span class="text font-weight-bold animation">here</span>
            </a>
        </div>
        <div class="education-programs">
            <div class="title text-uppercase animation">Further Education Programs</div>
            <div class="sub-title gradient text-uppercase animation">Empowering the Lab Scientists of Tomorrow</div>
            <div class="image">
                <div class="sticker animation">
                    <div class="sticker-title text-uppercase">new!</div>
                    <div class="sticker-text text-uppercase">starting autumn 2023</div>
                </div>
            </div>
        </div>
        <div id="educational-offering" class="study-program">
            <div class="study-program-title animation">Study Program</div>
            <a href="https://www.kalaidos-fh.ch/en-GB/Studiengaenge/U-CAS-Certificate-of-Advanced-Studies-Project-Management-for-Lab-Automation-IVD-and-Analytics"
               target="_blank"
               class="study-program-subtitle animation">
                CAS FH in Project Management for Lab Automation, In-Vitro Diagnostics
                and Analytics
            </a>
            <div class="study-program-starting-date animation">Starting date: September 2023</div>
            <div class="study-program-button animation">
                <a href="https://www.kalaidos-fh.ch/en-GB/Studiengaenge/U-CAS-Certificate-of-Advanced-Studies-Project-Management-for-Lab-Automation-IVD-and-Analytics"
                   target="_blank"
                   class="study-program-register">
                    <span class="text-uppercase">info & registration</span>
                </a>
                <a href="assets/files/Factsheet-CAS-Project-Management-for-Lab-Automatisation-IVD-and-Analytics.pdf"
                   download class="study-program-download">
                    <span class="text-uppercase">download fact sheet</span>
                </a>
            </div>
            <div class="study-program-text animation">Switzerland is a world leader in laboratory technology with around
                25,000 employees generating approximately CHF 5 billion in sales. However, the rapidly advancing market
                demands top talent.
            </div>
            <div class="study-program-text animation">In partnership with <a href="https://www.kalaidos-fh.ch/de-CH"
                                                                             target="_blank">Kalaidos</a>, The House of
                Lab Science offers the CAS FH study program in Project Management for Lab Automation,
                In-Vitro Diagnostics, and Analytics. It equips professionals in the laboratory technology sector with
                essential knowledge, skills, and career benefits. The program is recognized worldwide, provides an
                opportunity to network with industry professionals and experts, as well as real-world experience in
                project management for lab automation and IVD. Participants also receive access to the “My House of
                Labs” online community platform, allowing them to continue to develop their skills and advance their
                careers beyond the program's end.
            </div>
        </div>
        <div id="translational-research" class="translational-research">
            <div class="title text-uppercase animation">Translational research</div>
            <div class="sub-title gradient text-uppercase animation">Building the Lab of Tomorrow, Today</div>
            <div class="image"></div>
            <div class="translational-research-content">
                <div class="translational-research-title animation">Problem</div>
                <div class="translational-research-text animation">Switzerland has world-class academic research
                    institutions and a thriving life sciences industry. However, there is a need for more translational
                    research to bridge the gap between scientific discoveries and practical applications.
                </div>
                <div class="translational-research-title animation">Solution</div>
                <div class="translational-research-text animation">A potential solution is the convergence of
                    technologies like augmented reality, artificial intelligence, remote access, and lab automation to
                    create remote automated labs – “labs of the future”. These labs enable researchers to conduct
                    experiments remotely, without physically entering the lab, optimizing experimental design and
                    utilizing lab automation. However, there is a need for formal and coordinated training programs in
                    forward-looking translational science, specifically focusing on lab automation and project
                    management skills.
                </div>
                <div class="translational-research-title animation">Our Offer</div>
                <div class="translational-research-text animation">Our Translational research projects aim to develop
                    training
                    programs that specifically cater to the skills needed for lab automation and project management in
                    the labs of the future.
                </div>
            </div>
        </div>
    </div>
</app-page-layout>
