import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef, HostListener, Inject,
    NgZone,
    OnInit, PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsCategoryPublicList } from 'projects/shared-models/src/lib/news-category.list';
import { NewsModel } from 'projects/shared-models/src/lib/news.model';
import { fromEvent } from 'rxjs';
import {
    debounceTime,
    filter,
    map,
    pairwise,
    throttleTime,
} from 'rxjs/operators';
import { NewsService } from 'src/app/modules/news/news.service';
import { ToastrService } from 'ngx-toastr';
import {isPlatformBrowser, Location} from '@angular/common';
import { MediaFilterModel } from '../../models/media-filter.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild('searchFilterInput') searchFilterInput!: ElementRef;
    @ViewChild('scroller') scroller: CdkVirtualScrollViewport;

    showMoreOn: boolean;
    expandFilters = true;
    hideFilters = false;
    selectedNews: NewsModel;
    newsTypeList = NewsCategoryPublicList;
    yearsList: Array<number>;
    tagList: Array<string>;
    searchFilter = '';
    categoryFilter: Array<string>;
    tagFilter: Array<string>;
    yearFilter: Array<string>;
    currentFilters: string;
    pageSize = 5;
    pageIndex = 0;
    exclusivePageIndex = 0;
    newsList: Array<any>;
    newsListExclusive: Array<any>;
    showSignIn = false;
    showMore = false;
    imagesWidth = [];
    imagesHeight = [];

    dropdownSettings = {
        singleSelection: true,
        textField: 'item_text',
        enableCheckAll: false,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        closeDropDownOnSelection: true,
        allowSearchFilter: true,
    };

    dropdownTagsSettings = {
        singleSelection: false,
        textField: 'item_text',
        enableCheckAll: false,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        closeDropDownOnSelection: true,
        allowSearchFilter: true,
        limitSelection: 3,
    };
    private observer: IntersectionObserver;

    constructor(
        private newsServices: NewsService,
        private route: ActivatedRoute,
        private toastrService: ToastrService,
        private location: Location,
        private ngZone: NgZone,
        public element: ElementRef,
        @Inject(PLATFORM_ID) private platformId: any,
        private titleService: Title
    ) {
        this.yearsList = this.getYearsFromYear(2020);
        this.categoryFilter = new Array();
        this.tagFilter = new Array();
        this.yearFilter = new Array();
        this.newsListExclusive = new Array();
        this.currentFilters = JSON.stringify(this.getFilters());
        this.newsServices.getTags().subscribe((data) => {
            this.tagList = data;
        });

        this.loadExclusiveNews();

        this.loadNews().then(() => {
            this.route.params.subscribe((params) => {
                if (params.title) {
                    this.newsServices
                        .getStory(params.title)
                        .subscribe((res) => {
                            this.selectedNews = res;
                        });
                } else {
                    this.newsServices.getMainStory(false).subscribe((res) => {
                        this.selectedNews = res;
                        history.replaceState(
                            null,
                            '',
                            window.location.href +
                                '/' +
                                this.getTitle(res.title)
                        );
                    });
                }
            });
        });
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.subscribeElementView();
        }
        this.expandFilters = window.innerWidth > 800;
        this.titleService.setTitle('Life Science articles, news and much more  | HLS');
    }

    ngAfterViewInit(): void {
        fromEvent(this.searchFilterInput.nativeElement, 'keyup')
            .pipe(debounceTime(500))
            .subscribe(() => {
                this.filtersChanged();
            });

        // detect add new items
        this.scroller
            .elementScrolled()
            .pipe(
                map(() => this.scroller.measureScrollOffset('bottom')),
                pairwise(),
                filter(([y1, y2]) => y2 < y1 && y2 < 541),
                throttleTime(200)
            )
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.fetchMoreNews();
                });
            });
    }

    resetFilters(): void {
        this.categoryFilter = [];
        this.tagFilter = [];
        this.yearFilter = [];
    }

    getFilters(): MediaFilterModel {
        return {
            search: this.searchFilter,
            category: this.categoryFilter,
            tag: this.tagFilter,
            year: this.yearFilter,
        };
    }

    filtersChanged(): void {
        const aux = JSON.stringify(this.getFilters());
        if (this.currentFilters !== aux) {
            this.currentFilters = aux;
            this.loadNews();
        }
    }

    loadNews(): Promise<boolean> {
        return new Promise((resolve) => {
            this.pageIndex = 0;
            this.newsServices
                .getNews(
                    this.getFilters(),
                    'createDate,desc',
                    this.pageIndex,
                    this.pageSize * 2,
                    false
                )
                .subscribe((data) => {
                    this.pageIndex = 2;
                    this.newsList = data;

                    if (this.newsList.length > 0) {
                        this.selectedNews = this.newsList[0];
                    } else {
                        this.selectedNews = null;
                    }

                    resolve(true);
                });
        });
    }

    loadExclusiveNews(): void {
        let contentSize;

        if (window.innerWidth >= 1440) {
            contentSize = 8;
        } else if (window.innerWidth <= 800) {
            contentSize = 4;
        } else {
            contentSize = 6;
        }

        this.newsServices
            .getNews(
                this.getFilters(),
                'createDate,desc',
                this.exclusivePageIndex++,
                contentSize,
                true
            )
            .subscribe((data) => {
                this.newsListExclusive = this.newsListExclusive.concat(data);

                if (data.length < contentSize) {
                    this.showMore = false;
                    this.newsListExclusive = this.newsListExclusive.concat(
                        new Array(contentSize - data.length)
                    );
                } else this.showMore = true;
            });
    }

    fetchMoreNews(): void {
        this.newsServices
            .getNews(
                this.getFilters(),
                'createDate,desc',
                this.pageIndex++,
                this.pageSize,
                false
            )
            .subscribe((data) => {
                this.newsList = this.newsList.concat(data);
            });
    }

    getYearsFromYear(year: number): Array<number> {
        const years = new Array<number>();
        for (let i = new Date().getFullYear(); i >= year; i--) {
            years.push(i);
        }
        return years;
    }

    readExclusiveContent(): void {
        this.showSignIn = true;
    }

    decodeText(text): string {
        return decodeURIComponent(text);
    }

    selectNews(news: NewsModel): void {
        this.imagesWidth = [];
        this.imagesHeight = [];
        if (news.link) {
            window.open(news.link, '_blank');
        } else {
            const link = this.getTitle(news.title);
            this.location.go(`/media/${link}`);
            this.selectedNews = news;
        }
    }

    copyHyperlink(news: NewsModel): void {
        let link = window.location.href.split('/media')[0] + '/media/';
        link += this.getTitle(news.title);
        this.toastrService.success('Article link was copied to clipboard.');
        navigator.clipboard.writeText(link);
    }

    getTitle(title: string): string {
        const spacesReplaced = title.replace(/ /g, '-');
        return encodeURIComponent(spacesReplaced).replace(/\(/g, '%28').replace(/\)/g, '%29');
    }

    private handleIntersect(entries): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
            }
            else{
                entry.target.classList.remove('active');
            }
        });
    }

    private subscribeElementView(): void {
        this.destroyObserver();
        this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) =>
        {
            this.handleIntersect(entries);
        });
        const animationElements = document.querySelectorAll('.animation');
        animationElements.forEach((el) => this.observer.observe(el));
    }
    private destroyObserver(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    ngAfterViewChecked(): void {
        const mediaContainer = document.querySelector('.media-container');
        const parentElement = mediaContainer.querySelector('.text');
        const image = parentElement.querySelectorAll('* img');
        let index = 0;
        image.forEach(img => {
            if (!this.imagesWidth[index]) {
                this.imagesWidth[index] = img.clientWidth;
                this.imagesHeight[index] = img.clientHeight;
                index++;
            }
        });
        this.changeAspectRationOfImages();
    }
    private changeAspectRationOfImages(): void
    {
        const mediaContainer = document.querySelector('.media-container');
        const parentElement = mediaContainer.querySelector('.text');
        const image = parentElement.querySelectorAll('* img');

        let index = 0;
        image.forEach(img => {
            let parentWidth = mediaContainer.clientWidth - 32;
            if (mediaContainer.clientWidth > 400)
            {
                parentWidth -= 32;
            }
            img.setAttribute('style', `width: ${ this.imagesWidth[index]}px; height: ${ this.imagesHeight[index]}px;`);
            if (mediaContainer.clientWidth < this.imagesWidth[index])
            {
                const aspectRatio = this.imagesHeight[index] / this.imagesWidth[index];
                const calculatedWidth = parentWidth;
                const calculatedHeight = parentWidth * aspectRatio;
                // Set the new width and height of the image
                img.setAttribute('style', `width: ${calculatedWidth}px; height: ${calculatedHeight}px;`);
            }
            index++;
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.changeAspectRationOfImages();
    }
}
