import {AfterViewChecked, AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { NewsModel } from 'projects/shared-models/src/lib/news.model';
import { NewsService } from 'src/app/modules/news/news.service';

@Component({
    selector: 'app-media-article',
    templateUrl: './media-article.component.html',
    styleUrls: ['./media-article.component.scss'],
})
export class MediaArticleComponent implements OnInit, AfterViewChecked {
    selectedNews: NewsModel;

    imagesWidth = [];
    imagesHeight = [];
    constructor(
        private newsServices: NewsService,
        private route: ActivatedRoute,
        private meta: Meta
    ) {
        this.route.params.subscribe((params) => {
            if (params['title']) {
                this.newsServices.getStory(params['title']).subscribe((res) => {
                    this.selectedNews = res;
                });
            } else {
                window.location.href = '/';
            }
        });
    }

    ngOnInit(): void {}

    decodeText(text): string {
        return decodeURIComponent(text);
    }

    updateShareMeta() {
        this.meta.updateTag({
            property: 'og:title',
            content: this.selectedNews.title,
        });
        this.meta.updateTag({
            property: 'og:url',
            content: document.URL,
        });
        this.meta.updateTag({
            property: 'og:image',
            content: this.selectedNews.picture,
        });
        this.meta.updateTag({
            property: 'og:image:secure_url',
            content: this.selectedNews.picture,
        });
        this.meta.updateTag({
            property: 'og:description',
            content: '',
        });
        this.meta.updateTag({ property: 'og:image:width', content: '300' });
        this.meta.updateTag({ property: 'og:image:height', content: '200' });
    }

    ngAfterViewChecked(): void {
        const mediaContainer = document.querySelector('.media-container');
        const parentElement = mediaContainer.querySelector('.text');
        const image = parentElement.querySelectorAll('* img');
        let index = 0;
        image.forEach(img => {
            if (!this.imagesWidth[index]) {
                this.imagesWidth[index] = img.clientWidth;
                this.imagesHeight[index] = img.clientHeight;
                index++;
            }
        });
        this.changeAspectRationOfImages();
    }
    private changeAspectRationOfImages(): void
    {
        const mediaContainer = document.querySelector('.media-container');
        const parentElement = mediaContainer.querySelector('.text');
        const image = parentElement.querySelectorAll('* img');

        let index = 0;
        image.forEach(img => {
            let parentWidth = mediaContainer.clientWidth - 32;
            if (mediaContainer.clientWidth > 400)
            {
                parentWidth -= 32;
            }
            img.setAttribute('style', `width: ${ this.imagesWidth[index]}px; height: ${ this.imagesHeight[index]}px;`);
            if (mediaContainer.clientWidth < this.imagesWidth[index])
            {
                const aspectRatio = this.imagesHeight[index] / this.imagesWidth[index];
                const calculatedWidth = parentWidth;
                const calculatedHeight = parentWidth * aspectRatio;
                // Set the new width and height of the image
                img.setAttribute('style', `width: ${calculatedWidth}px; height: ${calculatedHeight}px;`);
            }
            index++;
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.changeAspectRationOfImages();
    }
}
