import { Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class Safe {
  constructor(private sanitizer: DomSanitizer) { }

  transform(html): SafeHtml {
      if (!html) {
          return '';
      }
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
