import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommunityComponent } from './components/community/community.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { MediaComponent } from './components/media/media.component';
import { ContactComponent } from './components/contact/contact.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatFormFieldModule,
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { ToastrModule } from 'ngx-toastr';
import {
    RecaptchaModule,
    RecaptchaFormsModule,
    RECAPTCHA_SETTINGS,
    RecaptchaSettings,
} from 'ng-recaptcha';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommingSoonComponent } from './components/comming-soon/comming-soon.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { SharedModule } from './shared/shared/shared.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { UserResolver } from './resolvers/user.resolver';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
import { RouteReuseStrategy } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MediaArticleComponent } from './components/media-article/media-article.component';
import { LocationComponent } from './components/location/location.component';
import { LabScienceAcademyComponent } from './components/lab-science-academy/lab-science-academy.component';

function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    return () =>
        keycloak.init({
            config: {
                url: environment.keycloak.url,
                realm: environment.keycloak.realm,
                clientId: environment.keycloak.clientId,
            },
            initOptions: {
                pkceMethod: 'S256',
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html',
                checkLoginIframe: false,
            },
        });
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 25,
};

@NgModule({
    declarations: [
        AppComponent,
        AboutComponent,
        HeaderComponent,
        FooterComponent,
        CommunityComponent,
        OurServicesComponent,
        MediaComponent,
        ContactComponent,
        PageLayoutComponent,
        CommingSoonComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        SubscribeComponent,
        SpinnerComponent,
        CarouselComponent,
        ModalConfirmComponent,
        MediaArticleComponent,
        LocationComponent,
        LabScienceAcademyComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BrowserAnimationsModule,
        PerfectScrollbarModule,
        NgScrollbarModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
        }),
        RecaptchaModule,
        RecaptchaFormsModule,
        MatDialogModule,
        MatCardModule,
        SharedModule,
        KeycloakAngularModule,
        MatIconModule,
        NgMultiSelectDropDownModule,
        ScrollingModule,
    ],
    exports: [NgScrollbarModule],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'legacy' },
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6LdIbhQdAAAAAJ1Im4U76K6MUIVCVCXMTwv9IQNr',
            } as RecaptchaSettings,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        UserResolver,
        {
            provide: RouteReuseStrategy,
            useClass: CacheRouteReuseStrategy,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
