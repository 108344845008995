<app-page-layout>
    <div pageContent class="media-container">
        <div class="press-release">
            <div class="news-author-date">
                <a
                    *ngIf="selectedNews?.link"
                    [href]="selectedNews?.link"
                    class="author"
                    >{{ selectedNews?.author }}</a
                >
                <span *ngIf="!selectedNews?.link" class="author">{{
                    selectedNews?.author
                }}</span>
                &#183;
                <div class="date">
                    {{ selectedNews?.createDate | date: "dd MMM yyyy" }}
                </div>
            </div>
            <div class="pre-title">
                <div class="news-tags">
                    <div
                        class="news-tag"
                        *ngFor="let tag of selectedNews?.tags"
                    >
                        {{ tag }}
                    </div>
                </div>
                <div
                    class="article-type"
                    [ngClass]="
                        selectedNews?.category
                            ? selectedNews?.category.split(' ')[0].toLowerCase()
                            : ''
                    "
                >
                    {{ selectedNews?.category }}
                </div>
            </div>
            <div class="title">
                {{ selectedNews?.title }}
            </div>
            <div>
                <img [src]="selectedNews?.picture" />
            </div>
            <div
                class="text"
                [innerHTML]="decodeText(selectedNews?.content) | safeHtml"
            ></div>
            <div
                class="press-buttons"
                [class.has-attachment]="selectedNews?.attachment">
                <a
                    class="download-button"
                    *ngIf="selectedNews?.attachment"
                    [href]="selectedNews?.attachment"
                    target="_blank">
                    <div class="icon-download"></div>
                    <div>Download</div>
                </a>
            </div>
        </div>
    </div></app-page-layout
>
