<app-page-layout>
    <div pageContent class="contact-container">
        <div class="contact-card">
            <div class="map-image"></div>
            <div class="form-container">
                <div class="form-title">We’d Love to Hear From YOU</div>
                <div class="form-title-info">
                    Whether you’d like to rent, join our community, book our
                    services, take a tour or are just curious – we’re here to
                    answer any and all of your questions.
                </div>
                <form [formGroup]="contactForm" class="d-flex flex-column">
                    <app-form-input
                        [formControl]="contactForm.controls.fullName"
                        [isRequired]="true"
                        [submitted]="formSubmitted"
                        placeholder="Full Name"
                    ></app-form-input>
                    <app-form-input
                        [formControl]="contactForm.controls.email"
                        [isRequired]="true"
                        [submitted]="formSubmitted"
                        placeholder="E-mail"
                    ></app-form-input>
                    <app-form-input
                        [formControl]="contactForm.controls.subject"
                        [isRequired]="true"
                        [submitted]="formSubmitted"
                        placeholder="Subject"
                    ></app-form-input>
                    <app-form-input
                        [formControl]="contactForm.controls.message"
                        [isRequired]="true"
                        [isTextarea]="true"
                        [submitted]="formSubmitted"
                        placeholder="Your message here"
                    >
                    </app-form-input>
                    <re-captcha formControlName="recaptcha"> </re-captcha>
                </form>
                <button class="btn btn-primary extend" (click)="send()">
                    Send
                </button>
            </div>
        </div>
    </div>
</app-page-layout>
