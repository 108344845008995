import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeComponent } from '../subscribe/subscribe.component';

@Component({
    selector: 'app-comming-soon',
    templateUrl: './comming-soon.component.html',
    styleUrls: ['./comming-soon.component.scss'],
})
export class CommingSoonComponent implements OnInit {
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

    countDownDate = new Date('Apr 04, 2022 00:00:00').getTime();
    now = new Date().getTime();
    days: any;
    hours: any;
    minutes: any;
    seconds: any;

    constructor(private dialog: MatDialog) {}

    ngOnInit(): void {
        this.countDownFunction();
    }

    onCloseModal(): void {
        this.closeModal.emit();
    }

    countDownFunction(): void {
        this.now = new Date().getTime();
        if (this.countDownDate > this.now) {
            const timeleft = this.countDownDate - this.now;

            this.days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            this.hours = Math.floor(
                (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            this.minutes = Math.floor(
                (timeleft % (1000 * 60 * 60)) / (1000 * 60)
            );
            this.seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

            setTimeout(() => {
                this.countDownFunction();
            }, 1000);
        } else {
            this.days = 0;
            this.hours = 0;
            this.minutes = 0;
            this.seconds = 0;
        }
    }

    subscribeToNewsletter(): void {
        this.dialog.open(SubscribeComponent);
        this.onCloseModal();
    }
}
