<div class="forgot-password-container">
    <div class="logo"></div>
    <div class="cards-container card-1" *ngIf="!emailSent">
        <mat-card class="card-type">
            <div>
                <div class="text-big">Reset password</div>
                <div class="text-small">
                    Enter your email and we’ll send a reset link to set a new
                    password.
                </div>
                <app-form-input
                    [formControl]="username"
                    [isRequired]="true"
                    placeholder="Username or email"
                    [isLowerCase]="true"
                >
                </app-form-input>
                <div class="user-error" *ngIf="username.errors?.notExists">
                    User not found.
                </div>
                <button
                    class="continue-button"
                    [disabled]="!username.valid"
                    (click)="username.valid && send()"
                >
                    Send
                </button>
                <a class="back-button" routerLink="/dashboard"
                    >&#60;&#60; Back to Login</a
                >
            </div>
        </mat-card>
    </div>
    <div class="cards-container card-2" *ngIf="emailSent">
        <mat-card class="card-type">
            <div>
                <div class="text-big">Check your email</div>
                <div class="text-small">
                    We sent a link to {{ this.username.value }}. Go to your
                    email and click the link to reset your password.
                </div>
                <a routerLink="/dashboard"
                    ><button class="continue-button">Sign in</button></a
                >
            </div>
        </mat-card>
    </div>

    <div class="footer-image">
        <div class="terms-privacy">
            <a class="terms" routerLink="/terms-and-conditions" target="_blank"
                >Terms & Conditions</a
            >
            <a class="privacy" routerLink="/privacy-policy" target="_blank"
                >Privacy policy</a
            >
        </div>
    </div>
</div>
